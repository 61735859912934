import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/includes/Navbar";
import Error from "./components/pages/Error";
import ForgotPassword from "./components/pages/ForgotPassword";
import Home from "./components/pages/Home/Home";
import Login from "./components/pages/Login";
import Pricing from "./components/pages/Pricing";
import ResetPassword from "./components/pages/ResetPassword";
import Services from "./components/pages/Services/Services";
import SignUp from "./components/pages/SignUp";
import UserProfile from "./components/user/UserProfile";
import Verify from "./components/pages/Verify";
import Categories from "./components/user/Categories";
import ChooseService from "./components/user/ChooseService";
import UploadDocument from "./components/user/UploadDocument";
import StyledDropzone from "./components/includes/Basic";
import Sidebar from "./components/includes/Sidebar";
import Payment from "./components/user/Payment";
import OrderReceived from "./components/user/OrderReceived";
import Drafts from "./components/user/Drafts/Drafts";
import Settings from "./components/user/Settings";
import PricingOrder from "./components/user/PricingOrder";
import Editor from "./components/editor/Editor";
import EditorProfile from "./components/editor/EditorProfile";
import User from "./components/user/User";
import EditorLogin from "./components/editor/EditorLogin";
import AssessmentOne from "./components/editor/AssessmentOne";
import EditorSubmitOne from "./components/editor/EditorSubmitOne";
import EditorAssessments from "./components/editor/EditorAssessments";
import SecondAssessment from "./components/editor/SecondAssessment";
import ThirdAssessment from "./components/editor/ThirdAssessment";
import Plans from "./components/pages/Plans";
import UserLogin from "./components/user/UserLogin";
import Esl from "./components/pages/ESL/Esl";
import PlaceOrder from "./components/pages/Services/PlaceOrder";
import Dashboard from "./components/user/Dashboard/Dashboard";
import InProgress from "./components/user/InProgress/InProgress";
import OrderHistory from "./components/user/OderHistory/OrderHistory";
import CreateOrders from "./components/pages/CreateOrder/CreateOrders";
import EditorDashboard from "./components/editor/Dashboard/EditorDashboard";
import Feed from "./components/editor/Feeds/Feed";
import SingleFeed from "./components/editor/Feeds/SingleFeed";
import EditorOrderHistory from "./components/editor/OrderHistory/EditorOrderHistory";
import EditorCompletedOrders from "./components/editor/CompletedOrders/EditorCompletedOrders";
import AwaitingApproval from "./components/editor/AwaitingApproval/AwaitingApproval";
import OrderCreated from "./components/editor/OrderCreated";
import SubmitOrder from "./components/editor/SubmitOrder/SubmitOrder";
import SingleOrder from "./components/user/OderHistory/SingleOrder";
import About from "./components/pages/About/About";
import Deliverables from "./components/editor/Deliverables/Deliverables";
import ShowDeliverables from "./components/editor/Deliverables/ShowDeliverables";
import Transactions from "./components/editor/Transactions/Transactions";
import TransactionDetails from "./components/editor/Transactions/TransactionDetails";
import Withdrawal from "./components/editor/Withdrawal/Withdrawal";
import PasswordReset from "./components/pages/PasswordReset";
import Payments from "./components/editor/Payments/Payments";
import UserCompletedOrders from "./components/user/CompletedOrders/userCompletedOrders";
import AdminDashboard from "./components/Admin/Dashboard/AdminDashboard";
import Users from "./components/Admin/Users/Users";
import AdminLogin from "./components/Admin/Login/AdminLogin";
import UserDetails from "./components/Admin/Users/UserDetails";
import Editors from "./components/Admin/Editors/Edtiors";
import EditorDetails from "./components/Admin/Editors/EditorDetails";
import Orders from "./components/Admin/Orders/Orders";
import OrderDetails from "./components/Admin/Orders/OrderDetails";
import Interviews from "./components/Admin/Interview/Interviews";
import AdminPayments from "./components/Admin/Payments/Payments";
import PaymentDetails from "./components/Admin/Payments/PaymentDetails";
import AdminTransactions from "./components/Admin/Transactions/Transactions";
import TransactionDetail from "./components/Admin/Transactions/TransactionDetail";
import CreatePassword from "./components/pages/CreatePassword";
import Contact from "./components/pages/Contact/Contact";

const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Navbar />
            <Home />
          </Route>
          <Route path="/pricing">
            <Navbar />
            <Pricing />
          </Route>
          <Route path="/services">
            <Navbar />
            <Services />
          </Route>
          <Route path="/about">
            <Navbar />
            <About />
          </Route>
          <Route path="/contact">
            <Navbar />
            <Contact />
          </Route>
          <Route path="/register">
            <SignUp />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/password-reset">
            <PasswordReset />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/set-password">
            <CreatePassword />
          </Route>
          <Route path="/verify">
            <Verify />
          </Route>
          <Route path="/categories">
            <Categories />
          </Route>
          <Route path="/choose-service">
            <ChooseService />
          </Route>
          <Route path="/upload-document">
            <UploadDocument />
          </Route>
          <Route path="/sidebar">
            <Sidebar />
          </Route>
          <Route path="/basic">
            <StyledDropzone />
          </Route>
          {/* User */}
          <Route path="/user-profile">
            <UserProfile />
          </Route>
          <Route path="/user/login">
            <UserLogin />
          </Route>
          <Route path="/in-progress">
            <InProgress />
          </Route>
          <Route path="/order-history">
            <OrderHistory />
          </Route>
          <Route path="/draft">
            <Drafts />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/pricing-order">
            <PricingOrder />
          </Route>
          <Route path="/payment">
            <Payment />
          </Route>
          <Route
            path="/create-order/:id"
            component={(props) => (
              <>
                <Navbar />
                <CreateOrders {...props} />
              </>
            )}
          ></Route>
          <Route
            path="/flutterwave/verify-payment/"
            component={(props) => (
              <>
                <OrderReceived {...props} />
              </>
            )}
          ></Route>
          <Route path="/user/register">
            <User />
          </Route>
          <Route path="/user">
            <Dashboard />
          </Route>
          <Route
            path="/esl/:id"
            component={(props) => (
              <>
                <Navbar />
                <Esl {...props} />
              </>
            )}
          ></Route>
          <Route
            path="/place-order/:id"
            component={(props) => (
              <>
                <Navbar />
                <PlaceOrder {...props} />
              </>
            )}
          ></Route>
          <Route
            path="/single-order/:id"
            component={(props) => (
              <>
                <SingleOrder {...props} />
              </>
            )}
          ></Route>
          {/* Editor */}
          <Route path="/editor/register">
            <Editor />
          </Route>
          <Route path="/editor/login">
            <EditorLogin />
          </Route>
          <Route path="/editor-profile">
            <EditorProfile />
          </Route>
          <Route path="/editor/assessment">
            <EditorAssessments />
          </Route>
          <Route
            path="/assessment-one/:id"
            component={(props) => (
              <>
                <AssessmentOne {...props} />
              </>
            )}
          ></Route>
          <Route
            path="/assessment-two/:id"
            component={(props) => (
              <>
                <SecondAssessment {...props} />
              </>
            )}
          ></Route>
          <Route path="/assessment-three">
            <ThirdAssessment />
          </Route>
          <Route path="/submit-assessment-one">
            <EditorSubmitOne />
          </Route>
          <Route path="/plans">
            <Plans />
          </Route>
          <Route path="/completed-orders">
            <UserCompletedOrders />
          </Route>
          <Route path="/editor/dashboard">
            <EditorDashboard />
          </Route>
          <Route path="/editor/feeds">
            <Feed />
          </Route>
          <Route
            path="/single-feed/:id"
            component={(props) => (
              <>
                <SingleFeed {...props} />
              </>
            )}
          ></Route>
          <Route path="/editor/order-history">
            <EditorOrderHistory />
          </Route>
          <Route path="/editor/completed-orders">
            <EditorCompletedOrders />
          </Route>
          <Route path="/editor/awaiting-approval">
            <AwaitingApproval />
          </Route>
          <Route path="/editor/order-create">
            <OrderCreated />
          </Route>
          <Route
            path="/editor/submit-order/:id"
            component={(props) => (
              <>
                <SubmitOrder {...props} />
              </>
            )}
          ></Route>
          <Route path="/editor/deliverables">
            <Deliverables />
          </Route>
          <Route
            path="/editor/show-deliverables/:id"
            component={(props) => (
              <>
                <ShowDeliverables {...props} />
              </>
            )}
          ></Route>
          <Route path="/editor/transactions">
            <Transactions />
          </Route>
          a
          <Route
            path="/editor/transaction/:id"
            component={(props) => (
              <>
                <TransactionDetails {...props} />
              </>
            )}
          ></Route>
          <Route path="/editor/withdrawal">
            <Withdrawal />
          </Route>
          <Route path="/editor/payments">
            <Payments />
          </Route>
          {/* Admin */}
          <Route path="/admin/login">
            <AdminLogin />
          </Route>
          <Route path="/admin/dashboard">
            <AdminDashboard />
          </Route>
          <Route path="/admin/users">
            <Users />
          </Route>
          <Route path="/admin/editors">
            <Editors />
          </Route>
          <Route path="/admin/orders">
            <Orders />
          </Route>
          <Route path="/admin/interviews">
            <Interviews />
          </Route>
          <Route path="/admin/transactions">
            <AdminTransactions />
          </Route>
          <Route path="/admin/payments">
            <AdminPayments />
          </Route>
          <Route
            path="/admin/user-details/:id"
            component={(props) => (
              <>
                <UserDetails {...props} />
              </>
            )}
          ></Route>
          <Route
            path="/admin/editor-details/:id"
            component={(props) => (
              <>
                <EditorDetails {...props} />
              </>
            )}
          ></Route>
          <Route
            path="/admin/order-details/:id"
            component={(props) => (
              <>
                <OrderDetails {...props} />
              </>
            )}
          ></Route>
          <Route
            path="/admin/transaction-details/:id"
            component={(props) => (
              <>
                <TransactionDetail {...props} />
              </>
            )}
          ></Route>
          <Route
            path="/admin/payment-details/:id"
            component={(props) => (
              <>
                <PaymentDetails {...props} />
              </>
            )}
          ></Route>
          <Route path="*">
            <Error />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
