import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";
import "../Users/Users.css";
import './Orders.css'

const OrderDetails = (props) => {
  const orderId = props?.match?.params?.id;
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");
  const [editors, setEditors] = useState([]);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/orders/${orderId}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setOrders(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, [orderId]);

  // Fetch active editors
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/editors?is_active=1`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setEditors(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, []);

  // Assign order to editor
  const assignOrder = (editor_id) => {
   console.log(editor_id);
    axios.defaults.withCredentials = true;
    const baseURL = process.env.REACT_APP_BASEURL;
    const response = axios
      .get(`${baseURL}/sanctum/csrf-cookie`)
      .then((response) => {
        const baseURL = process.env.REACT_APP_BASEURL;
        const order_id = orderId
        return axios.put(
          `${baseURL}/api/v1/admin/orders/${order_id}`,
          {
            order_id: orderId,
            editor_id,
          },
          {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          }
        );
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          toast.success(res.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/orders");
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setLoading(false);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
        }
      });
    return response;
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row feture-tabs bg-white">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="top"></div>
            <ul className="nav nav-pills mb-3">
              <li>
                <a
                  className="nav-link active"
                  data-bs-toggle="pill"
                  href="#tab1"
                >
                  Order Details
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab2">
                  User
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab3">
                  Editor
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab4">
                  Payments
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab1">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>Order Details</h4>
                            <div className="mx-4">
                              <div className="row">
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Title <br />
                                    <span className="text-dark">
                                      {orders?.title}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Category <br />
                                    <span className="text-dark">
                                      {orders?.service?.name}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Description <br />
                                    <span className="text-dark">
                                      {orders?.service?.description}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Reference <br />
                                    <span className="text-dark">
                                      {orders?.reference}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Urgency <br />
                                    <span className="text-dark">
                                      {orders?.urgency}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Price <br />
                                    <span className="text-dark">
                                      {orders?.currency} {orders?.price}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Field <br />
                                    <span className="text-dark">
                                      {orders?.field}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    English Version <br />
                                    <span className="text-dark">
                                      {orders?.english_version}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Style Guide <br />
                                    <span className="text-dark">
                                      {orders?.style_guide}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Special Instruction <br />
                                    <span className="text-dark">
                                      {orders?.special_instruction
                                        ? orders?.special_instruction
                                        : "No Special Instruction"}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Created <br />
                                    <span className="text-dark">
                                      {orders?.created_at}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Completed <br />
                                    <span className="text-dark">
                                      {orders?.completed_at}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Document <br />
                                    <span className="text-dark">
                                      <a
                                        className="badge rounded-pill bg-primary"
                                        href={orders?.document}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        download
                                      </a>
                                    </span>
                                  </p>
                                </div>
                              </div>
                              {orders?.deliverable !== null ? <div className="row">
                                <h3 className="my-5">Deliverables</h3>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Track Changes <br />
                                    <span className="text-dark">
                                      <span className="text-dark">
                                        <a
                                          className="badge rounded-pill bg-primary"
                                          href={
                                            orders?.deliverable?.track_changes
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          download
                                        </a>
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Final Document <br />
                                    <span className="text-dark">
                                      <span className="text-dark">
                                        <a
                                          className="badge rounded-pill bg-primary"
                                          href={
                                            orders?.deliverable?.final_document
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          download
                                        </a>
                                      </span>
                                    </span>
                                  </p>
                                </div>
                              </div> : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane fade show" id="tab2">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>User Details</h4>
                            <div className="mx-4">
                              <div className="row">
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    First Name <br />
                                    <span className="text-dark">
                                      {orders?.user?.first_name}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Last Name <br />
                                    <span className="text-dark">
                                      {orders?.user?.last_name}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Email <br />
                                    <span className="text-dark">
                                      {orders?.user?.email}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Phone <br />
                                    <span className="text-dark">
                                      {orders?.user?.phone}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane fade show" id="tab3">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow pb-5 mb-4">
                          <div className="card-body">
                            <h4>Editor Details</h4>
                            {orders?.editor !== null ? (
                              <div className="mx-4">
                                <div className="row">
                                  <div className="col-md-6">
                                    <p className="text-primary">
                                      First Name <br />
                                      <span className="text-dark">
                                        {orders?.editor?.first_name}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="text-primary">
                                      Last Name <br />
                                      <span className="text-dark">
                                        {orders?.editor?.last_name}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="text-primary">
                                      Email <br />
                                      <span className="text-dark">
                                        {orders?.editor?.email}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="text-primary">
                                      Phone <br />
                                      <span className="text-dark">
                                        {orders?.editor?.phone}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="text-primary">
                                      Wallet <br />
                                      <span className="text-dark">
                                        {orders?.editor?.wallet?.balance}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-12">
                                    <p className="text-primary">
                                      Bio <br />
                                      <span className="text-dark">
                                        {orders?.editor?.bio}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="container">
                                <div className="row">
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    <span>
                                      {" "}
                                      <i className="bi bi-exclamation-triangle"></i>{" "}
                                      No Editor
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="dropdown">
                                      <p
                                        className="btn btn-primary px-4 dropdown-toggle"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        select Editor
                                      </p>

                                      <ul
                                        className="dropdown-menu p-3"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        {editors?.map((editor) => (
                                          <div key={editor?.id}>
                                            <li className="editor-name" onClick={() => assignOrder(editor?.uuid)}>
                                              {editor?.first_name}{" "}
                                              {editor?.last_name}
                                            </li>
                                          </div>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane fade show" id="tab4">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>Payments</h4>
                            <div className="mx-4">
                              {orders?.payments?.length !== 0 ? (
                                <div className="table-responsive">
                                  <table className="table responsive table-hover mb-5">
                                    <thead>
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">PROVIDER</th>
                                        <th scope="col">REFERENCE</th>
                                        <th scope="col">DATE</th>
                                        <th scope="col">STATUS</th>
                                        <th scope="col">AMOUNT</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading ? (
                                        <div
                                          className="d-flex justify-content-center align-items-center"
                                          style={{ marginTop: "200px" }}
                                        >
                                          <div
                                            className="spinner-border text-primary"
                                            role="status"
                                          >
                                            <span className="sr-only"></span>
                                          </div>
                                        </div>
                                      ) : (
                                        orders?.payments?.map(
                                          (payment, index) => {
                                            const {
                                              id,
                                              provider,
                                              reference,
                                              amount,
                                              currency,
                                              status,
                                              created_at,
                                            } = payment;
                                            return (
                                              <tr key={id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{provider}</td>
                                                <td>{reference}</td>
                                                <td>{created_at}</td>
                                                <td
                                                  style={{
                                                    color:
                                                      status === "successful"
                                                        ? "#00E324"
                                                        : status ===
                                                          "unsuccessful"
                                                        ? "#E33700"
                                                        : "#E3B100",
                                                  }}
                                                >
                                                  {status}
                                                </td>
                                                <td>
                                                  {currency} {amount}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="container">
                                  <div className="row">
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      <span>
                                        {" "}
                                        <i className="bi bi-exclamation-triangle"></i>{" "}
                                        No payments
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
