import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import { EditorId } from "../includes/Navbar";

const baseURL = process.env.REACT_APP_BASEURL;

const SecondAssessment = (props) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [text, setText] = useState("");
  const [redirect, setRedirect] = useState("");
  const [disableConfirm, setDisableConfirm] = useState(false)
  const [buttonLoad, setbuttonLoad] = useState(false);

  const testId = props?.match?.params?.id

  const submitTest = (e) => {
    e.preventDefault();
    setDisableConfirm(true);
    setbuttonLoad(true);
    axios
      .post(
        `${baseURL}/api/v1/editors/${EditorId}/tests/${testId}/submit`,
        {
          text,
        },
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        setDisableConfirm(false);
        setbuttonLoad(false);
        toast.success(response.data.message);
        if (response.data.status === "success") {
          setTimeout(() => {
            setRedirect("/editor/assessment");
          }, 4000);
        }
      })
      .catch((error) => {
        setDisableConfirm(false);
        setbuttonLoad(true);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        } else if (error.response.status === 413) {
          setRedirect("/editor/assessment");
        }
      }
      );
  };

  useEffect(() => {
    axios
      .get(
        `${baseURL}/api/v1/editors/${EditorId}/tests/${testId}`,
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        setQuestions(response.data.data.questions);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, [testId]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container">
      <div className="row">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "300px" }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <div className="card border-0 my-5">
            <div className="card-body">
              <ToastContainer autoClose={3000} />
              <h4 className="my-3">Second Assesement</h4>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <form onSubmit={submitTest}>
                      {questions.map((question) => {
                        const { id, content } = question;
                        return (
                          <div key={id}>
                            <div className="row">
                              <div className="col-md-6">
                                <p className="text-dark">{content}</p>
                              </div>
                              <div className="col-md-6">
                                <div>
                                  <textarea
                                    required
                                    className="form-control"
                                    placeholder="Write the correct essay here"
                                    id="floatingTextarea2"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    style={{ height: "300px" }}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <button
                        type="submit"
                        className="btn btn-primary shadow"
                        disabled={disableConfirm}
                      >
                        Submit
                        {buttonLoad && (
                          <div
                            className="spinner-border text-light mx-3"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondAssessment;
