import React from 'react'
import { Link } from 'react-router-dom'
import mastercard from '../images/mastercard.png'
import paypal from '../images/PayPal.png'
import verve from '../images/verve.png'
import visa from '../images/visa.png'

const Payment = () => {
  return (
    <div className='container mt-5'>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-md-6">
          <div className="my-5 card w-100 border-0 shadow">
          <div className="card-body">
              <div className="form-head">
                <h3 className='mb-5'>Payment</h3>
                <form>
                  <div className="my-3">
                    <label htmlFor="name" className='text-secondary'>Cardholder name</label>
                  <input type="text"
                   className='form-control'
                   placeholder='Bukola Olayade'
                  />
                  </div>
                  <div className="my-3">
                    <label htmlFor="name" className='text-secondary'>Card number</label>
                  <input type="tel"
                   className='form-control'
                  inputmode="numeric" maxLength="19" placeholder="xxxx xxxx xxxx xxxx"
                  />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="my-3">
                      <label htmlFor="name" className='text-secondary'>Expiry date</label>
                      <input type="text"
                      className='form-control'
                      placeholder='11/22'
                      />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="my-3">
                        <label htmlFor="name" className='text-secondary'>CVV</label>
                        <input type="tel"
                        className='form-control'
                        maxLength="3"
                        placeholder='***'
                        />
                      </div>
                    </div>
                    <div className='my-5'>
                      <Link to='order-received' className='btn btn-primary w-100'>Pay NGN15,000</Link>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                      <Link to='./'>
                        <img src={verve} className='m-2' alt="verve" />
                      </Link>
                      <Link to='./'>
                        <img src={paypal} className='m-2' alt="paypal" />
                      </Link>
                      <Link to='./'>
                        <img src={mastercard} className='m-2' alt="mastercard" />
                      </Link>
                      <Link to='./'>
                        <img src={visa} className='m-2' alt="visa" />
                      </Link>
                    </div>
                  </div>
                </form>
              </div>           
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
