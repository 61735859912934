import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";

const ShowDeliverables = (props) => {
  const [redirect, setRedirect] = useState("");
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [trackChanges, setTrackChanges] = useState("");
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [buttonLoad, setbuttonLoad] = useState(false);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/deliverables/${props?.match?.params?.id}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setJobs(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.clear();
          setRedirect("/editor/login");
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/editor/order-history");
          }, 3000);
        }
      });
  }, [props?.match?.params?.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableConfirm(true);
    setbuttonLoad(true);
    const data = new FormData();
    data.append("upload_preset", "write-right");
    data.append("track_changes", trackChanges);
    data.append("final_document", selectedFile);
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .post(
        `${baseURL}/api/v1/deliverables/${props?.match?.params?.id}/confirm`,
        data,
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
          "Content-Type": "multipart/form-data",
        }
      )
      .then((response) => {
        console.log(response.data.message);
        toast.success(response.data.message);
        setDisableConfirm(false);
        setbuttonLoad(false);
        setTimeout(() => {
          setRedirect("/editor/deliverables");
        });
      }, 3000)
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          toast.error(error.response.data.message);
          setbuttonLoad(false);
          setDisableConfirm(false)
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setbuttonLoad(false);
        }
      });
  };

  // const rejectOrder = (e) => {
  //   e.preventDefault()
  //   console.log('beasky rejected this')
  //   console.log(rejectedOrder)

  //   const baseURL = process.env.REACT_APP_BASEURL;
  //   axios
  //     .post(
  //       `${baseURL}/api/v1/deliverables/${props?.match?.params?.id}/reject`,
  //       {
  //         rejection_reason: rejectedOrder
  //       },
  //       {
  //         xsrfHeaderName: "X-XSRF-TOKEN",
  //         withCredentials: true,
  //         "Content-Type": "multipart/form-data",
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       toast.success(response.data.message);
  //       setTimeout(() => {
  //         setRedirect("/editor/deliverables");
  //       });
  //     }, 3000)
  //     .catch((error) => {
  //       console.log(error);
  //       if (error.response.status === 422) {
  //         console.log(error.response.data.errors);
  //         toast.error(error.response.data.message);
  //       } else if (error.response.status === 400) {
  //         toast.error(error.response.data.message);
  //       }
  //     });
  // }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container-fluid my-5">
      <ToastContainer />
      <div className="row">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <div className="row">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "200px" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-md-10">
                    <div className="card border-0 shadow">
                      <div className="card-body">
                        <p className="my-3">
                          <strong>Track Changes</strong>
                        </p>
                        <a
                          href={jobs.track_changes}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Download Track Changes
                        </a>
                        <p className="my-3">
                          <strong>Final Document</strong>
                        </p>
                        <a
                          href={jobs.final_document}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Donwload Final Document
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <form onSubmit={handleSubmit}>
                      <div className="col-md-10">
                        <div className="card border-0 shadow mb-5">
                          <div className="card-body">
                            <p className="head">
                              <i className="bi bi-file-earmark-fill"></i>
                              Document(s)
                            </p>
                            <p>
                              Upload document if there's any corrections made to
                              the final document, else you can just confirm the
                              order
                            </p>
                            <p className="">Track Changes</p>
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="file"
                                  className="form-control text-primary btn-light"
                                  name="file"
                                  onChange={(e) =>
                                    setTrackChanges(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                            <p className="">Final Document</p>
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="file"
                                  className="form-control text-primary btn-light"
                                  name="file"
                                  onChange={(e) =>
                                    setSelectedFile(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-4">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button
                                      className="btn btn-primary shadow"
                                      type="submit"
                                      disabled={disableConfirm}
                                    >
                                      Confirm Order
                                      {buttonLoad && (
                                        <div
                                          className="spinner-border text-light mx-3"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          role="status"
                                        >
                                          <span className="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      )}
                                    </button>
                                  </div>
                                  {/* <div className="col-md-6">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop"
                                    >
                                      reject order
                                    </button>

                                    <div
                                      className="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabIndex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="staticBackdropLabel"
                                            >
                                              Reject Order
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <p>Reasons for Rejection</p>
                                            <div className="form-floating mb-3">
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={rejectedOrder}
                                                onChange={(e) =>
                                                  setRejectedOrder(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-secondary"
                                              data-bs-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={rejectOrder}
                                            >
                                              Reject
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowDeliverables;
