import React from 'react'
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import './Services.css'

const Services = () => {
  return (
    <div className="service">
      <div className="service-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="text-white" style={{ marginTop: "190px" }}>
                Editing Services
              </h2>
              <h5 className="about-point">Write Right Consulting Services</h5>
              <p className="about-desc">
                Write Right is an editing, transcribing and accent polishing
                company that assists students, individuals, corporate bodies
                proofread, edit and re-write their books and theses and other
                publications.
              </p>
              <a
                href="#academic-editing"
                className="btn btn-outline-warning mt-5"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="py-5 bg-white" id="academic-editing">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-primary my-4">
                <Link to="esl/24af6440-ba53-11ec-82c5-5765c60682ba">
                  Academic Editing
                </Link>
              </h3>
              <p className="service-list">
                Thesis, Dissertation, Essays, Articles, Proposals, Assignments,
                Research Projects.
              </p>
              <p>
                Your supervisor(s) will not expend time on your language and
                grammar! Most advisors barely have time for the research
                questions. You need an English expert.
              </p>
              <p>
                Let us help you. Focus on your content, while we deal with your
                language.
              </p>
              <p>
                Write Right will revise your work for style, clarity, cohesion
                and logical flow. We will review your spelling, grammar,
                punctuation, sentence structure, expression and usage. We offer
                substantive editing services designed to give your work an
                overhaul.{" "}
              </p>
              <p>
                Our editors for doctoral degrees are PhD qualified and
                professors who will overhaul your work and deliver the best
                output including:{" "}
              </p>
              <p>
                <span className="delivery">Structure:</span>(Re)organisation of
                sections, logical flow and presentation
              </p>
              <p>
                <span className="delivery">Unity: </span>of structure,
                reasoning, findings and conclusion
              </p>
              <p>
                <span className="delivery">Content: </span>Precise expression
                and validity of arguments
              </p>
              <p>
                <span className="delivery">Style: </span>correct expression and
                appropriate academic tone
              </p>
              <p>
                <span className="delivery">Language:</span>accuracy in spelling,
                punctuation, grammar, tense & sentence structure
              </p>
              <p>
                <span className="delivery">Format: </span>firm adherence to
                required referencing and formatting style
              </p>
              <p>
                <span className="delivery">Elimination: </span>of redundancies
                and extraneous materials.
              </p>
              <p>
                We have experienced editors and academic advisors who will add
                value and offer constructive criticism to ensure your success.
                Whether a first degree, masters or doctorate, choose Write Right
                to review and improve your work.
              </p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="py-3">What you will receive</h3>
                <ul>
                  <li className="lists">
                    Tracked revision of your work so you can see changes and
                    comments on your work
                  </li>
                  <li className="lists">
                    An edited clean version with the changes already embedded.
                  </li>
                  <li className="lists">
                    Short observations and major concerns
                  </li>
                  <li className="lists">
                    Formatting of citations and references (If desired)
                  </li>
                </ul>
                <p>
                  Be assured that your manuscript will be kept confidential.
                  Uploads and downloads to our server are securely encrypted. We
                  have strict privacy policies and handle most major file types.
                  We respect your privacy.
                </p>
                <Link
                  to="esl/24af6440-ba53-11ec-82c5-5765c60682ba"
                  className="btn btn-secondary shadow my-3"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-light" id="book-editing">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-primary my-4">
                <Link to="esl/24b02260-ba53-11ec-87cd-8dc5a4b2e2cd">
                  Book Editing
                </Link>
              </h3>
              <p className="service-list">
                Books, Novels, Plays, Poems, Biographies, Memoirs, Self – help
                Books, Nonfiction, e books.
              </p>
              <p>
                Be it a novel, volume of poetry, biography or self-help book, it
                feels amazing to finish what you’ve started and accomplish
                what’s on your heart. Write Right supports you to critique,
                rewrite and complete your writing.
              </p>
              <p>
                At Write Right, we will provide affordable high-quality editing
                you need and make you a better writer. Our editors will
                eliminate errors, review your work for stylistic and mechanical
                accuracy and provide you with the best of expressions. What is
                more? We will complement your creativity and inspire greater
                writing.
              </p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="py-3">What you will receive</h3>
                <ul>
                  <li className="lists">
                    Tracked revision of your work so you can see changes and
                    comments on your work
                  </li>
                  <li className="lists">
                    An edited clean version with the changes already embedded.
                  </li>
                  <li className="lists">
                    Corrections of spelling, grammatical errors, typos and
                    inconsistencies; improved clarity, organizational flow;
                    stronger authorial voice; improved tone, style, point of
                    view, word choice and sentence structure
                  </li>
                  <li className="lists">
                    Extensive critical commentaries and editors notes
                  </li>
                </ul>
                <p>
                  <span className="delivery">
                    Your copyright remains yours exclusively!
                  </span>{" "}
                  We will never make a claim on your copyright. Be assured that
                  your manuscript will be kept confidential. Uploads and
                  downloads to our server are securely encrypted. We have strict
                  privacy policies and handle most major file types. We respect
                  your privacy. We will keep your files secure.
                </p>
                <Link
                  to="esl/24b02260-ba53-11ec-87cd-8dc5a4b2e2cd"
                  className="btn btn-primary shadow my-3"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-white" id="transcribing-services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-primary my-4">
                <Link to="esl/24b0b3a0-ba53-11ec-8c61-89c5b8b07b87">
                  Transcribing Services
                </Link>
              </h3>

              <p>
                What is the purpose of an incorrect transcript? While automated
                transcription services have their place, speech recognition
                software will never be able to deliver results like human
                transcriptionists. Even with advancements in speech recognition
                software, human transcriptionists still provide the most
                accurate transcription services possible.
              </p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="py-3">
                  Write Right Transcribing Services offer:
                </h3>
                <ul>
                  <li className="lists">
                    Transcription of meetings, phone conversations, group
                    discussions, interviews.
                  </li>
                  <li className="lists">
                    Transcription and transformation of audio and video
                    materials (sermons, seminars, conferences) into texts and
                    books.
                  </li>
                  <li className="lists">
                    Legal transcription including public hearings, judicial
                    hearings, arbitration and interrogations.
                  </li>
                </ul>
                <Link
                  to="esl/24b0b3a0-ba53-11ec-8c61-89c5b8b07b87"
                  className="btn btn-secondary shadow my-3"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-light" id="accent-training">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-primary my-4">
                <Link to="esl/24b150f0-ba53-11ec-acd2-4b5f6bab62bf">
                  Accent Training
                </Link>
              </h3>

              <p>
                Why should anyone be held back by an accent? Are you missing out
                on professional, educational and social opportunities because of
                unpolished accent? Write Right Accent Training Services equips
                our clients with mother tongue accent reduction techniques to
                attain a polished accent. At Write Right, we help you achieve
                fluency and boost your confidence.
              </p>
              <p>
                Write Right accent training provides drills in pronunciation,
                voice use, social skills and nonverbal communication.
              </p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="py-3">
                  We offer training on accent polishing for:
                </h3>
                <ul>
                  <li className="lists">Pastors</li>
                  <li className="lists">Actors/actresses</li>
                  <li className="lists">Public Speakers</li>
                  <li className="lists">Motivational Speakers</li>
                  <li className="lists">Teachers</li>
                  <li className="lists">Individuals</li>
                  <li className="lists">Groups</li>
                  <li className="lists">Students</li>
                  <li className="lists">Political figures and Dignitaries</li>
                  <li className="lists">Professionals</li>
                  <li className="lists">Corporate clients</li>
                </ul>
                <Link
                  to="esl/24b150f0-ba53-11ec-acd2-4b5f6bab62bf"
                  className="btn btn-primary shadow my-3"
                >
                  Accent Training
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-white" id="SOP">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-primary lists my-4">
                <Link to="/esl/24b1a0e0-ba53-11ec-a157-41c2cc5b7031">
                  Statement of Purpose
                </Link>
              </h3>

              <p>
                Your academic qualification is not the only criterion for
                admission into the university of your choice. To be accepted
                into a graduate program, applicants must submit a robust
                Statement of Purpose. A Statement of Purpose allows you to tell
                an admissions committee who you are, what has influenced your
                chosen career path, professional interests, and future goals. It
                reveals your value, vision, aspirations, long-term goals,
                motivation for course selection and reasons for choice of
                university. Our consultants and writers ensure that every aspect
                is positively foregrounded.
              </p>
              <p>
                Every statement of purpose that we craft is{" "}
                <span className="delivery">distinctive, impressive</span>, and{" "}
                <span className="delivery">striking</span>, guaranteeing the
                very best chance of success.
              </p>
              <p>
                If you have already written your statement but still want it to
                be refined, just give us the draft and we will transform it.
              </p>
              <Link
                to="/esl/24b1a0e0-ba53-11ec-a157-41c2cc5b7031"
                className="btn btn-secondary shadow my-3"
              >
                Statement of Purpose
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-light" id="LOR">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-primary lists my-4">
                <Link to="esl/24b259a0-ba53-11ec-aae3-63794a6c4848">
                  Letters of Recommendation
                </Link>
              </h3>

              <p>
                Your professors or employers may be too busy to craft a
                professional Letter of Recommendation for you. We will write on
                their behalf as we know:
              </p>
              <ul>
                <li>• What to include or not</li>
                <li>• The suitable length</li>
                <li>
                  • How to connect your relationship with the referrer and{" "}
                </li>
                <li>• Ways to properly highlight your competencies</li>
              </ul>
              <p>
                You just get them signed, our professional touch will make you
                stand out among the crowd!
              </p>
              <Link
                to="esl/24b259a0-ba53-11ec-aae3-63794a6c4848"
                className="btn btn-primary shadow my-3"
              >
                Letters of Recommendation
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-white" id="cover-letter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-primary lists my-4">
                <Link to="esl/24b259a0-ba53-11ec-aae3-63794a6c4848">
                  Cover Letter
                </Link>
              </h3>

              <p>
                A cover letter is a necessary document which explains to an
                employer why you are the best candidate for a particular job.
                Having a well written cover letter is the difference between
                getting that job or not. It gives you an edge over others! At
                Write Right, we create cover letters that get you noticed and
                make you stand out from the crowd. We use unique skills and
                experience in composing the perfect eye-catching Cover Letter
                for you. Let us support you and showcase your exceptional
                strength and value with a cover letter tailored to your needs as
                our end goal is to satisfy our clients and make sure they get
                value for their money. We can assure you of an exclusive cover
                letter that is skilfully written and proofread with an eagle
                eye.
              </p>
              <Link
                to="esl/24b259a0-ba53-11ec-aae3-63794a6c4848"
                className="btn btn-secondary shadow my-3"
              >
                Cover Letter
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Services
