import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form'

const Plans = () => {
  const { register, handleSubmit} = useForm()
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios.get(`${baseURL}/api/v1/plans`, {
      xsrfHeaderName: "X-XSRF-TOKEN",
      withCredentials: true,
    })
    .then(response => {
      console.log(response.data.data);
      setLoading(false)
      setPlans(response.data.data)
    })
    .catch(error => {
      console.log(error);
    })
  }, []);

const onSubmit = (data) => {
  console.log(data);

}

  return (
    <div>
      <div className="container mt-5">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-8">
            <div className="card border-0 shadow">
              <div className="card-body">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <h3 className="text-center p-3">pricing plans</h3>
                    <div className="col text-center">
                      <h5 className="my-4">Unit Price</h5>
                      {plans?.map((plan) => {
                        return <p key={plan.id} className="text-dark">$ {plan.unit_price}</p>;
                      })}
                    </div>
                    <div className="col text-center">
                      <h5 className="my-4">Flat Price</h5>
                      {plans?.map((plan) => {
                        return (
                          <p key={plan.id} className="text-dark">
                            $ {plan.flat_price}
                          </p>
                        );
                      })}
                    </div>
                    <div className="col text-center">
                      <h5 className="my-4">Urgency</h5>
                      {plans?.map((plan) => {
                        return (
                          <p key={plan.id} className="text-dark">
                            {plan.urgency}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="file" {...register("file")} name="file" />
              <button>submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
