import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";
import "./Editor.css";

const EditorDetails = (props) => {
  const editorId = props?.match?.params?.id;
  const [editors, setEditors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");
  const [buttonLoad, setButtonLoad] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const baseURL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/editors/${editorId}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setEditors(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, [editorId]);

  const banEditor = (e) => {
    e.preventDefault();
    setButtonLoad(true);
    setDisableButton(false)
    axios
      .post(
        `${baseURL}/api/v1/admin/editors/${editorId}/ban`,
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setDisableButton(false)
        setButtonLoad(false)
      })
      .catch((error) => {
        console.log(error);
        setButtonLoad(false)
        setDisableButton(false)
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        } else if (error.response.status === 413) {
          setRedirect("/editor/assessment");
        }
      });
  };

  // Activate Editor
  const activateEditor = (e) => {
    e.preventDefault();
    setButtonLoad(true);
    setDisableButton(false);
    axios
      .post(`${baseURL}/api/v1/admin/editors/activate`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      }, {
        editor_id: props.match.params.id
      })
      .then((response) => {
        toast.success(response.data.message);
        setDisableButton(false);
        setButtonLoad(false);
      })
      .catch((error) => {
        console.log(error);
        setButtonLoad(false);
        setDisableButton(false);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        } else if (error.response.status === 413) {
          setRedirect("/editor/assessment");
        }
      });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row feture-tabs bg-white">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="top"></div>
            <ul className="nav nav-pills mb-3">
              <li>
                <a
                  className="nav-link active"
                  data-bs-toggle="pill"
                  href="#tab1"
                >
                  Editor Details
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab2">
                  Orders
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab3">
                  Transactions
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab1">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>Editor Details</h4>
                            <div className="mx-4">
                              <div className="row">
                                <div className="col-md-6">
                                  <p>
                                    FIRST NAME <br />
                                    <span className="editor-details">
                                      {editors?.first_name}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    LAST NAME <br />
                                    <span className="editor-details">
                                      {editors?.last_name}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    EMAIL <br />
                                    <span className="editor-details">
                                      {editors?.email}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    PHONE <br />
                                    <span className="editor-details">
                                      {editors?.phone}
                                    </span>
                                  </p>
                                </div>

                                {editors?.status === "active" && (
                                  <div className="col-md-6">
                                    <p>
                                      Wallet <br />
                                      <span className="editor-details">
                                        {editors?.wallet?.balance}
                                      </span>
                                    </p>
                                  </div>
                                )}

                                <div className="col-md-6">
                                  <p>
                                    Status <br />
                                    <span className="editor-details">
                                      {editors?.status}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-12">
                                  <p>
                                    BIO <br />
                                    <span className="editor-details">
                                      {editors?.bio ? editors?.bio : "No Bio"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 mb-5">
                                  {editors?.status === "active" && (
                                    <div>
                                      <p>Ban Editor</p>
                                      <button
                                        className="btn btn-danger shadow"
                                        onClick={banEditor}
                                        disabled={buttonLoad}
                                      >
                                        Ban Editor
                                        {buttonLoad && (
                                          <div
                                            className="spinner-border text-light mx-3"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            role="status"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        )}
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {editors?.status === "banned" && (
                                  <div className="col-md-6">
                                    <p>Unban Editor</p>
                                    <button
                                      className="btn btn-primary shadow"
                                      onClick={banEditor}
                                    >
                                      Unban Editor
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="row">
                                <div className="col-md-6 mb-5">
                                  {editors?.status === "inactive" && (
                                    <div>
                                      <p>Activate Editor</p>
                                      <button
                                        className="btn btn-success shadow"
                                        onClick={activateEditor}
                                        disabled={buttonLoad}
                                      >
                                        Activate Editor
                                        {buttonLoad && (
                                          <div
                                            className="spinner-border text-light mx-3"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            role="status"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        )}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane fade show" id="tab2">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>Orders</h4>
                            <div className="table-responsive">
                              <table className="table responsive table-hover table-borderless mt-2 mb-0">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>TITLE</th>
                                    <th>CATEGORY</th>
                                    <th>DESCRIPTION</th>
                                    <th>URGENCY</th>
                                    <th>WORDS</th>
                                    <th>PRICE</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {editors.orders.map((order, index) => {
                                    const {
                                      id,
                                      number_of_words,
                                      price,
                                      currency,
                                      title,
                                      urgency,
                                      document,
                                      service,
                                      uuid,
                                    } = order;
                                    return (
                                      <tr
                                        onClick={() =>
                                          setRedirect(
                                            "/admin/order-details/" + uuid
                                          )
                                        }
                                        key={id}
                                      >
                                        <th scope="row">{index + 1}</th>
                                        <td>{title}</td>
                                        <td>{service?.name}</td>
                                        <td>{service?.description}</td>
                                        <td>{urgency}</td>
                                        <td>{number_of_words}</td>
                                        <td>
                                          {currency} {price}
                                        </td>
                                        <td>
                                          <a
                                            className="badge rounded-pill bg-primary"
                                            href={document}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            download
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane fade show" id="tab3">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>Transactions</h4>
                            <div className="mx-4">
                              <div className="table-responsive">
                                <table className="table responsive table-hover mb-5">
                                  <thead>
                                    <tr style={{color: '#cccccc'}}>
                                      <th scope="col">#</th>
                                      <th scope="col">DESCRIPTION</th>
                                      <th scope="col">REFERENCE</th>
                                      <th scope="col">STATUS</th>
                                      <th scope="col">AMOUNT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading ? (
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ marginTop: "200px" }}
                                      >
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="sr-only"></span>
                                        </div>
                                      </div>
                                    ) : (
                                      editors?.transactions?.map((transaction, index) => {
                                        const {
                                          id,
                                          description,
                                          reference,
                                          amount,
                                          currency,
                                          status,
                                        } = transaction;
                                        return (
                                          <tr key={id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{description}</td>
                                            <td>{reference}</td>
                                            <td
                                              style={{
                                                color:
                                                  status === "confirmed"
                                                    ? "#00E324"
                                                    : status === "unsuccessful"
                                                    ? "#E33700"
                                                    : "#E3B100",
                                              }}
                                            >
                                              {status}
                                            </td>
                                            <td>
                                              {currency} {amount}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorDetails;
