import React from 'react'
import './Categories.css'
import Academic from '../images/academic.png'
import Admissions from '../images/admission.png'
import Author from '../images/author.png'
import Business from '../images/business.png'
import Job from '../images/job-application.png'
import Personal from '../images/personal.png'
import { Link } from 'react-router-dom'

const Categories = () => {
  return (
    <div className='container my-5'>
      <div className="row">
        <h2 className='my-5'>Choose a Category</h2>
        <div className="card p-4 border-0 shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
              <div className="card card-shadow">
                <div className="card-body text-center">
                  <img src={Academic} className='mb-3' alt="academic" />
                  <h4 className='mb-3'>Academic</h4>
                  <div className="row m-0">
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Dissertation</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Thesis</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Journal Article</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Essay</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Research Paper</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-shadow">
                <div className="card-body text-center">
                  <img src={Admissions} className='mb-3' alt="admissions" />
                  <h4 className='mb-3'>Admissions</h4>
                  <div className="row m-0">
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Dissertation</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Thesis</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Journal Article</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Essay</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Research Paper</span></Link>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-shadow">
                <div className="card-body text-center">
                  <img src={Author} className='mb-3' alt="author" />
                  <h4 className='mb-2'>Author</h4>
                  <div className="row m-0">
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Book</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Manuscript</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Novel</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Screenplay</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Sctipt</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Magazine</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Story</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Self-help</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Poetry</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Essay</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Research Paper</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="row">
              <div className="col-md-4">
              <div className="card card-shadow">
                <div className="card-body text-center">
                  <img src={Business} className='mb-3' alt="business" />
                  <h4 className='mb-2'>Business</h4>
                  <div className="row m-0">
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Dissertation</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Thesis</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Journal Article</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Essay</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Research Paper</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-shadow">
                <div className="card-body text-center">
                  <img src={Job} className='mb-3' alt="job" />
                  <h4 className='mb-2'>Job Application</h4>
                  <div className="row m-0">
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Dissertation</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Thesis</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Journal Article</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Essay</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Research Paper</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-shadow">
                <div className="card-body text-center">
                  <img src={Personal} className='mb-3' alt="personal" />
                  <h4 className='mb-2'>Personal</h4>
                  <div className="row m-0">
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Dissertation</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Thesis</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Journal Article</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-3">Essay</span></Link>
                    </div>
                    <div className="col-md-4 mb-2">
                      <Link to='/choose-service'><span className="badge rounded-pill bg-pill text-dark py-2 px-2">Research Paper</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Categories
