import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import verify from '../images/verify.png'

const OrderReceived = (props) => {
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState('')
  const [redirect, setRedirect] = useState("");

  const URLParams = props.location.search
  console.log(URLParams)

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(
        `${baseURL}/api/v1/payments/flutterwave/verify${URLParams}`,
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        setOrder(response.data);
        setLoading(false);
        setTimeout(() => {
          setRedirect("/in-progress");
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [URLParams]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center vh-100">
        <div className="col-md-6">
          <div className="my-5 card w-100 border-0 shadow">
            <div className="card-body">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              ) : (
                <div className="form-head">
                  <img src={verify} className="mx-auto d-block" alt="verify" />
                  <h4 className="text-center mt-2">{order.status}</h4>
                  <p className="text-center">{order.message}</p>
                  <div className="d-flex justify-content-center align-items">
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderReceived
