import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Redirect } from "react-router-dom";
// import Select from "react-select";
import { EditorId } from "../includes/Navbar";
import Sidebar from "./Sidebar/Sidebar";

const EditorProfile = () => {
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(true);
  const [error, setError] = useState("");
  const [details, setDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    bio: '',
  });
  const [redirect, setRedirect] = useState("");
  const [access] = useState(true)

  const handleEvent = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.defaults.withCredentials = true;
    const baseURL = process.env.REACT_APP_BASEURL;
    const response = axios
      .put(
        `${baseURL}/api/v1/editors/${EditorId}`,
        {
          first_name: details.first_name,
          last_name: details.last_name,
          email: details.email,
          phone: details.phone,
          second_level_access: access,
          bio: details.bio,
          academic_experience: [
            {
              institution: details.institution,
              course: details.course,
            },
          ],
          professional_experience: [
            {
              company: details.company,
              role: details.role,
            },
          ],
        },
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setLoading(false);
        setError('')
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          toast.error(error.response.data.message);
          setError(error.response.data.errors);
          setLoading(false);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setError(error.response.data.errors);
          setLoading(false);
        }
      });
    return response;
  };

  

  // const aquaticCreatures = [
  //   { label: "Abia State University", value: "Abia State University" },
  //   { label: "Abubakar Tafawa Balewa University", value: "Abubakar Tafawa Balewa University" },
  //   { label: "Achievers University, Owo", value: "Achievers University, Owo" },
  //   { label: "Adamawa State University", value: "Adamawa State University" },
  //   { label: "Adekunle Ajasin University", value: "Adekunle Ajasin University" },
  //   { label: "Adeleke University", value: "Adeleke University" },
  //   { label: "Admiralty University of Nigeria", value: "Admiralty University of Nigeria" },
  //   { label: "Afe Babalola University", value: "Afe Babalola University" },
  //   { label: "African University of Science and Technology", value: "African University of Science and Technology" },
  //   { label: "Ahmadu Bello University", value: "Ahmadu Bello University" },
  //   { label: "Ajayi Crowther University", value: "Ajayi Crowther University" },
  //   { label: "Akwa Ibom State University", value: "Akwa Ibom State University" },
  //   { label: "Alex Ekwueme Federal University, Ndufu-Alike", value: "Alex Ekwueme Federal University, Ndufu-Alike" },
  //   { label: "Al-Hikmah University", value: "Al-Hikmah University" },
  //   { label: "Al-Qalam University, Katsina", value: "Achievers University, Owo" },
  //   { label: "Ambrose Alli University", value: "Ambrose Alli University" },
  //   { label: "American University of Nigeria", value: "American University of Nigeria" },
  //   { label: "Anchor University, Lagos", value: "Anchor University, Lagos" },
  //   { label: "Arthur Jarvis University", value: "Arthur Jarvis University" },
  //   { label: "Atiba University", value: "Afe Babalola University" },
  //   { label: "Augustine University", value: "Augustine University" },
  //   { label: "Babcock University", value: "Babcock University" },
  //   { label: "Bauchi State University", value: "Bauchi State University" },
  //   { label: "Bayero University Kano", value: "Bayero University Kano" },
  // ];

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/editors/${EditorId}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        setDetails(response.data.data);
        setLoadings(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className="container-fluid my-5">
      {!loadings ? (
        <div className="row">
          <div className="col-md-3">
            <Sidebar />
          </div>
          <div className="col-md-9">
            <div className="card border-0 shadow">
              <div className="card-body">
                <div className="form-head">
                  <h3 className="text-primary">Editor Profile</h3>
                  <p className="text-dark">Personal Information </p>
                </div>
                <form className="form" onSubmit={handleEvent}>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="first_name">First name</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="John"
                        id="first_name"
                        name="first_name"
                        value={details.first_name}
                        onChange={(e) =>
                          setDetails({ ...details, first_name: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="last_name">Last name</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Doe"
                        id="last_name"
                        name="last_name"
                        value={details.last_name}
                        onChange={(e) =>
                          setDetails({ ...details, last_name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    placeholder="johndoe@yahoo.com"
                    id="email"
                    name="email"
                    value={details.email}
                    onChange={(e) =>
                      setDetails({ ...details, email: e.target.value })
                    }
                  />
                  <label htmlFor="phone">Phone number</label>
                  <input
                    type="tel"
                    className="form-control mb-3"
                    placeholder="+2347023483595"
                    id="phone"
                    name="phone"
                    value={details.phone}
                    onChange={(e) =>
                      setDetails({ ...details, phone: e.target.value })
                    }
                  />
                  <label htmlFor="phone">Bio</label>
                  <textarea
                    className="form-control mb-3"
                    placeholder="Write your bio here"
                    id="bio"
                    name="bio"
                    value={details.bio}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        bio: e.target.value,
                      })
                    }
                    style={{ height: "100px" }}
                  ></textarea>
                  <p className="text-danger">{error?.bio}</p>
                  <h4 className="my-4">Academic Experience</h4>
                  {details.academic_experience?.map((school, index) => {
                    return (
                      <div key={index}>
                        <label htmlFor="phone">Institution</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          placeholder="Babcock University"
                          id="institution"
                          name="institution"
                          value={school.institution}
                          onChange={(e) => {
                            var new_school = school.institution;
                            new_school?.push(e.target.value);
                            setDetails({
                              ...details,
                              institution: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="phone">Course of Study</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          placeholder="English language"
                          id="course"
                          name="course"
                          value={school.course}
                          onChange={(e) => {
                            var new_course = school.course;
                            new_course?.push(e.target.value);
                            setDetails({
                              ...details,
                              course: e.target.value,
                            });
                          }}
                        />
                        {/* <Select options={aquaticCreatures}
                         /> */}
                      </div>
                    );
                  })}

                  <h4 className="my-4">Professional Experience</h4>
                  {details.professional_experience?.map((work, index) => {
                    return (
                      <div key={index}>
                        <label htmlFor="phone">Company</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          placeholder="Google"
                          id="company"
                          name="company"
                          value={work.company}
                          onChange={(e) => {
                            var new_company = work.company;
                            new_company?.push(e.target.value);
                            setDetails({
                              ...details,
                              company: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="phone">Role</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          placeholder="Editor"
                          id="role"
                          name="role"
                          value={work.role}
                          onChange={(e) => {
                            var new_role = work.role;
                            new_role?.push(e.target.value);
                            setDetails({
                              ...details,
                              role: e.target.value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                  <button
                    type="submit"
                    className="btn btn-primary shadow"
                    disabled={loading}
                  >
                    Update Profile
                    {loading && <i className="fa fa-refresh fa-spin mx-2"></i>}
                  </button>
                </form>
              </div>
            </div>
          </div>

          <ToastContainer autoClose={3000} />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: "300px" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditorProfile;
