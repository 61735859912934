import axios from "axios";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { EditorId } from "../../includes/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import './Transactions.css'

const Transactions = () => {
  const [redirect, setRedirect] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(
        `${baseURL}/api/v1/editors/${EditorId}/transactions`,
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data.data);
        setTransactions(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container-fluid my-5">
      <div className="row my">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "200px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : transactions.length !== 0 ? (
            <div className="col-md-12">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h2 className="p-4">Transactions</h2>

                  <div className="table-responsive">
                    <table className="table responsive table-striped table-borderless mt-2 mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Description</th>
                          <th>Reference</th>
                          <th>Created At</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction, index) => {
                          const {
                            id,
                            uuid,
                            description,
                            reference,
                            status,
                            type,
                            created_at,
                            amount,
                          } = transaction;
                          return (
                            <tr
                              onClick={() =>
                                setRedirect("/editor/transaction/" + uuid)
                              }
                              key={id}
                            >
                              <td>
                                <i className="bi bi-arrow-left-right text-success"></i>
                              </td>
                              <td>{description}</td>
                              <td>{reference}</td>
                              <td>{created_at}</td>
                              <td>{type}</td>
                              <td>{status}</td>
                              <td>{amount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="card w-100 border-0 shadow">
                <div className="card-body p-0">
                  <p className="head">
                    <i className="bi bi-clock-history mx-2"></i> Transactions
                  </p>
                  <div className="container mt-4"></div>
                  <div className="container">
                    <div className="row">
                      <div className="alert alert-danger p-2" role="alert">
                        <span>
                          {" "}
                          <i className="bi bi-exclamation-triangle"></i> You
                          currrently have no transactions
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
