import React from "react";
import Footer from "../Footer/Footer";
import "./About.css";

const About = (props) => {
  return (
    <div className="about">
      <div className="about-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="about-head">About Us</h2>
              <h5 className="about-point">Write Right Consulting Services</h5>
              <p className="about-desc">
                Write Right is an editing, transcribing and accent polishing
                company that assists students, individuals, corporate bodies
                proofread, edit and re-write their books and theses and other
                publications.
              </p>
              <button type="button" className="btn btn-outline-warning">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="my-4">Our task</h3>
              <ul className="list">
                <li>
                  Work with you to ensure you are submitting a well prepared
                  piece of work
                </li>
                <li className="list">Eliminate errors and redundancies</li>
                <li>
                  Restructure paragraphs for coherence; logical flow and clarity
                  of ideas.
                </li>
                <li className="list">Format manuscripts</li>
                <li>Off doctorate level expertise to improve your contents</li>
                <li className="list">Polish your language </li>
                <li className="list">
                  Arrange references and ensure they conform to the specific
                  referencing styles.
                </li>
                <li>Ensure grammar flow, syntax, formatting </li>
                <li>
                  Eliminate various errors including:
                  <ul>
                    <li className="mx-3"> Capitalization</li>
                    <li className="mx-3"> Contractions</li>
                    <li className="mx-3"> Subject-verb agreement</li>
                    <li className="mx-3"> Passive voice </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container">
          <div className="row">
            <h2 className="center mb-5">Other Services include</h2>
            <div className="col-md-4 mb-4 other-service">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <p className="card-text">
                    Transcribe audio and video materials (sermons, seminars)
                    into book form.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 other-service">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <p className="card-text">
                    Offer training on accent polishing for pastors,
                    public/motivational speakers, teachers, individuals and
                    groups.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 other-service">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <p className="card-text">Crafting Statement of Purpose</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 other-service">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <p className="card-text">
                    Writing professional Recommendation Letters
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 other-service">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <p className="card-text">Creating exclusive Resumes</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 other-service">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <p className="card-text">
                    Providing outstanding Cover Letters
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>
            You can put an end to grammatical errors, spelling mistakes,
            improper spacing, poor punctuation, bad references among others if
            you collaborate with Write Right. We specialize in proofreading and
            turning your drafts into masterpieces.
          </p>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="vision">Our Vision</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 py-3">
              <img
                src="https://cdn.pixabay.com/photo/2021/10/08/06/09/glasses-6690310__340.jpg"
                className="img-fluid"
                alt="vision"
              />
            </div>
            <div className="col-md-6 vision-statement py-3">
              <h3>
                To become the leading proofreading, editing and transcribing
                platform in Africa and beyond
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="vision">Our Mision</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 vision-statement py-3">
              <h3>To help people meet their editorial needs</h3>
            </div>
            <div className="col-md-6 py-3">
              <img
                src="https://cdn.pixabay.com/photo/2018/07/13/09/33/target-3535310__340.jpg"
                className="img-fluid"
                alt="vision"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
