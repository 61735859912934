import axios from "axios";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { EditorId } from "../../includes/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import './Payments.css'

const Payments = ({ balance }) => {
  const [redirect, setRedirect] = useState("");
  const [payments, setPayments] = useState([]);
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [buttonLoad, setButtonLoad] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [details, setDetails] = useState({});
  const [bankDetails, setBankDetails] = useState({})

  useEffect(() => {
    console.log(EditorId);
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/editors/${EditorId}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        console.log(response.data.data.wallet);
        setDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, []);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/banks`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setPayments(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, []);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/editors/${EditorId}/bank-account`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setBankDetails(response.data['data']);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, []);

  

    const handleSubmit = (e) => {
      e.preventDefault();
      console.log("Account submitted");
      console.log(bank, account);
      setButtonLoad(true);
      setDisableButton(true);

      const baseURL = process.env.REACT_APP_BASEURL;
      axios
        .post(
          `${baseURL}/api/v1/editors/${EditorId}/bank-accounts`,
          {
            account_number: account,
            account_bank: bank,
          },
          {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log(response.data);
          toast.success(response.data.message);
          setButtonLoad(false);
          setDisableButton(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            console.log(error.response.data.errors);
            toast.error(error.response.data.message);
            setButtonLoad(false);
            setDisableButton(false);
          } else if (error.response.status === 400) {
            toast.error(error.response.data.message);
            setButtonLoad(false);
            setDisableButton(false);
          }
        });
    };

    const withdraw = (e) => {
      e.preventDefault();
      console.log("Money withdrawn");
      console.log(amount);

      const baseURL = process.env.REACT_APP_BASEURL;
      axios
        .post(
          `${baseURL}/api/v1/editors/${EditorId}/request-withdrawal`,
          {
            account_number: bankDetails.bank_name,
            account_bank: bankDetails.account_number,
          },
          {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log(response.data);
          toast.success(response.data.message);
          setButtonLoad(false);
          setDisableButton(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            console.log(error.response.data.errors);
            toast.error(error.response.data.message);
            setButtonLoad(false);
            setDisableButton(false);
          } else if (error.response.status === 400) {
            toast.error(error.response.data.message);
            setButtonLoad(false);
            setDisableButton(false);
          }
        });
    };


  if (redirect) {
    return <Redirect to={redirect} />;
  }

  // const bankNames = [
  //   // {
  //   //   label: payments.map((bank) => bank.name),
  //   //   value: payments.map((bank) => bank.code),
  //   // },

  //   { label: "fnkfl", value: "sv" },
  //   { label: "fnkfl", value: "sv" },
  // ];

  return (
    <div className="container-fluid my-5">
      <div className="row my">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <ToastContainer autoClose={3000} />
          <div className="col-md-12">
            <div className="card border-0 shadow">
              <div className="card-body">
                <h4 className="p-4">Payments</h4>
                <h6 className="pay-info">Add your bank details</h6>
                <div className="row">
                  <div className="col-md-6">
                    <form className="form" onSubmit={handleSubmit}>
                      <label htmlFor="banks">Select Bank</label>
                      <select
                        className="form-select mb-3"
                        aria-label="Bank"
                        type="text"
                        required
                        value={bank}
                        onChange={(e) => {
                          setBank(e.target.value);
                        }}
                      >
                        <option defaultValue="1">--Select--</option>
                        {payments?.map((bank) => {
                          const { id, code, name } = bank;
                          return (
                            <option key={id} value={code}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Account Number
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          placeholder="0123456789"
                          max="10"
                          value={account}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              setAccount(e.target.value);
                            }
                          }}
                        />
                      </div>
                      <button
                        className="btn btn-primary shadow mb-2"
                        type="submit"
                        disabled={disableButton}
                      >
                        Add Account
                        {buttonLoad && (
                          <div
                            className="spinner-border text-light mx-3"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-1 my-4">
            <div className="card border-0 shadow">
              <div className="card-body">
                <h4 className="my-2">Bank Details</h4>
                <div className="pay-info">
                  <p>
                    Account Name: <br />{" "}
                    <strong>{bankDetails.full_name}</strong>
                  </p>
                  <p>
                    Account Number: <br />{" "}
                    <strong>{bankDetails.account_number}</strong>
                  </p>
                  <p>
                    Bank: <br /> <strong>{bankDetails.bank_name}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-1 my-4">
            <div className="card border-0 shadow">
              <div className="card-body">
                <div className="col-md-6">
                  <h4 className="my-2">Withdraw</h4>
                  <p className="pay-info">
                    Balance{" "}
                    <span className="float-end">
                      {details?.wallet === null
                        ? "No Balance"
                        : details?.wallet?.balance}
                    </span>
                  </p>
                  <form className="form" onSubmit={withdraw}>
                    <div className="mb-3">
                      <label htmlFor="amount" className="form-label">
                        Amount
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={amount}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setAmount(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary border-0 shadow mb-2"
                    >
                      Proceed <i className="bi bi-caret-right-fill"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mx-1 my-4">
            <div className="card border-0 shadow">
              <div className="card-body">
                <div className="col-md-6">
                  <h4 className="my-2">Withdrawal History</h4>
                  <p className="pay-info">
                    Withdrawal (Cover Letter){" "}
                    <span className="float-end text-success">#2, 300</span>{" "}
                    <br /> <small>1 month ago</small>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Payments;
