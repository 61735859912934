import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";
import "../Users/Users.css";

const PaymentDetails = (props) => {
  const paymentId = props?.match?.params?.id;
  const [payments, setpayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/payments/${paymentId}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setpayments(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, [paymentId]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row feture-tabs bg-white">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="top"></div>
            <ul className="nav nav-pills mb-3">
              <li>
                <a
                  className="nav-link active"
                  data-bs-toggle="pill"
                  href="#tab1"
                >
                  Order Details
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab2">
                  User
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab1">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>Order Details</h4>
                            <div className="mx-4">
                              <div className="row">
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Amount <br />
                                    <span className="text-dark">
                                      {payments?.currency} {payments?.amount}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Provider <br />
                                    <span className="text-dark">
                                      {payments?.provider}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Reference <br />
                                    <span className="text-dark">
                                      {payments?.reference}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Status <br />
                                    <span className="text-dark">
                                      {payments?.status}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Date <br />
                                    <span className="text-dark">
                                      {payments?.created_at}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane fade show" id="tab2">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>User Details</h4>
                            <div className="mx-4">
                              <div className="row">
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    First Name <br />
                                    <span className="text-dark">
                                      {payments?.user?.first_name}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Last Name <br />
                                    <span className="text-dark">
                                      {payments?.user?.last_name}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Email <br />
                                    <span className="text-dark">
                                      {payments?.user?.email}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p className="text-primary">
                                    Phone <br />
                                    <span className="text-dark">
                                      {payments?.user?.phone}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
