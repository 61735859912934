import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";
import "../Users/Users.css";

const AdminPayments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/payments`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setPayments(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row bg-white">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="top"></div>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "200px" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <div className="card border-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">PROVIDER</th>
                          <th scope="col">REFERENCE</th>
                          <th scope="col">DATE</th>
                          <th scope="col">STATUS</th>
                          <th scope="col">AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payments.map((payment, index) => {
                          const {
                            id,
                            provider,
                            reference,
                            created_at,
                            uuid,
                            currency,
                            status,
                            amount,
                          } = payment;
                          return (
                            <tr
                              key={id}
                              onClick={() =>
                                setRedirect("/admin/payment-details/" + uuid)
                              }
                            >
                              <th scope="row">{index + 1}</th>
                              <td>{provider}</td>
                              <td>{reference}</td>
                              <td>{created_at}</td>
                              <td
                                style={{
                                  color:
                                    status === "successful"
                                      ? "#00E324"
                                      : status === "unsuccessful"
                                      ? "#E33700"
                                      : "#E3B100",
                                }}
                              >
                                {status}
                              </td>
                              <td>
                                {currency} {amount}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPayments;
