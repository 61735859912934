import axios from "axios";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
// import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { EditorId } from "../../includes/Navbar";
import "../../pages/CreateOrder/CreateOrders.css";
import Sidebar from "../Sidebar/Sidebar";

const SubmitOrder = (props) => {

  const order_id = props?.match?.params?.id;
  const [selectedFile, setSelectedFile] = useState('')
  const [trackChanges, setTrackChanges] = useState('')
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [buttonLoad, setbuttonLoad] = useState(false);

  const [redirect, setRedirect] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableConfirm(true);
    setbuttonLoad(true);
    const data = new FormData();
    data.append("upload_preset", "write-right");
    data.append("order_id", order_id);
    data.append("editor_id", EditorId);
    data.append("track_changes", trackChanges);
    data.append("final_document", selectedFile);
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .post(`${baseURL}/api/v1/deliverables`, data, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        console.log(response.data);
        toast.success(response.data.message);
        alert(response.data.message)
        setDisableConfirm(false);
        setbuttonLoad(false);
        setTimeout(() => {
          setRedirect('/editor/awaiting-approval')
      })
        }, 3000)
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          toast.error(error.response.data.message);
          setDisableConfirm(false);
          setbuttonLoad(false);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setDisableConfirm(false);
          setbuttonLoad(false);
        }
      });
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="mt-5">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <ToastContainer />
              <div className="card border-0 shadow mb-5">
                <div className="card-body">
                  <p className="head">
                    <i className="bi bi-file-earmark-fill"></i> Your Document(s)
                  </p>
                  <p className="text-center">Track Changes</p>
                  <div className="row center">
                    <div className="col-md-5">
                      <input
                        required
                        type="file"
                        className="form-control text-primary btn-light"
                        name="file"
                        onChange={(e) => setTrackChanges(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <p className="text-center">Final Document</p>
                  <div className="row center">
                    <div className="col-md-5">
                      <input
                        required
                        type="file"
                        className="form-control text-primary btn-light"
                        name="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center my-4">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary shadow w-100"
                        type="submit"
                        disabled={disableConfirm}
                      >
                        Submit
                        {buttonLoad && (
                          <div
                            className="spinner-border text-light mx-3"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubmitOrder;
