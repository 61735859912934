import React from "react";
import verify from "../images/verify.png";

const OrderCreated = () => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center vh-100">
        <div className="col-md-6">
          <div className="my-5 card w-100 border-0 shadow">
            <div className="card-body">
              <div className="form-head">
                <img
                  src={verify}
                  className="mx-auto d-block"
                  alt="OrderCreated"
                />
                <h4 className="text-center mt-2">Order Created</h4>
                <p className="text-center">
                  This order should be completed and submitted within 23 hours
                </p>

                <div className="d-flex justify-content-center align-item-center">
                  <button className="btn btn-sm btn-primary">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCreated;
