import React from 'react'
import { Link } from 'react-router-dom'
import StyledDropzone from '../includes/Basic'

const UploadDocument = () => {
  return (
    <div className='container my-5'>
      <div className="row">
        <h2 className='my-5'>Fill Details</h2>
        <div className="card service-card p-5 border-0 shadow">
          <div className="card-body">
            <div className="row mb-5">
              <div className="col-md-6">
                <form className=''>
                  <div className="m-3">
                    <label htmlFor='DocumentName' className="form-label text-secondary">Document Name</label>
                    <input type="text" className="form-control" placeholder='Postgraduate Thesis' />
                  </div>
                  <div className="m-3">
                    <label htmlFor='DeliveryTime' className="form-label text-secondary">Delivery Time</label> <br />
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                      <label className="form-check-label" htmlFor="inlineRadio1">24 hrs</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                      <label className="form-check-label" htmlFor="inlineRadio2">2 days</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option3" />
                      <label className="form-check-label" htmlFor="inlineRadio3">5 days</label>
                    </div>
                  </div>
                  <div className="m-3">
                    <label htmlFor='DocumentType' className="form-label text-secondary">Document Type</label>
                    <select className="form-select" aria-label="Default select example">
                      <option defaultValue disabled>Thesis</option>
                      <option value="1">Research Paper</option>
                      <option value="2">Journal Article</option>
                      <option value="3">Essay</option>
                    </select>
                  </div>
                  <div className="m-3">
                    <label htmlFor='EnglishStyle' className="form-label text-secondary">English Style</label>
                    <select className="form-select" aria-label="Default select example">
                      <option defaultValue>Select from:</option>
                      <option value="1">US</option>
                      <option value="2">British</option>
                      <option value="3">Canadian  </option>
                      <option value="4">Australian</option>
                    </select>
                  </div>


                </form>
              </div>
              <div className="col-md-6">
                <div className="m-3">
                    <label htmlFor='Description' className="form-label text-secondary">Description</label>
                    <textarea className="form-control" placeholder="Description" style={{height: '122px'}}></textarea>
                </div>
                <div className="col-md-6">
                <div className="m-3">
                    <label htmlFor='Content' className="form-label text-secondary">Content</label>
                    {/* <input type="file" className="form-control" id="exampleInputEmail1" /> */}
                    <StyledDropzone />
                  </div>
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <p className='my-0'>Number of words:</p>
                <h4 className='my-0'>15,303</h4>
              </div>
              <div className="col-md-3">
                <p className='my-0'>Approximate delivery time</p>
                <h4 className='my-0'>24 hours</h4>
                <p className='my-0'>Estimate based on previous jobs</p>
              </div>
              <div className="col-md-3">
                <p className='my-0'>Price</p>
                <h4 className='my-0'>From $9.99</h4>
                <p className='my-0'>Incl. VAT of $0.00</p>
              </div>
              <div className="col-md-3">
                <Link to='/pricing-order' className='btn btn-primary shadow'>Place Order</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadDocument
