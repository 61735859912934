import React, { useState } from 'react'
import './ChooseService.css'
import Proofreading from '../images/proofreading.png'
import CopyEditing from '../images/copy-editing.png'
import { Link } from 'react-router-dom'


const ChooseService = () => {
  const [active, setActive] = useState(false)
  const [inactive, setInactive] = useState(false)
  return (
    <div className='container my-5'>
      <div className="row">
        <h2 className='my-5'>Choose a Service</h2>
        <div className="card service-card p-5 border-0 shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div onClick={() => setActive(!active)} className={`card  my-4 c-shadow2 ${active ? 'c-shadow card-border' : ''}`}>
                <div className="card-body text-center">
                  <img src={Proofreading} className='my-5' alt="proofreading" />
                  <h4 className='pb-5'>Proofreading</h4>
                </div>
              </div>
              </div>
              <div className="col-md-6">
                <div onClick={() => setInactive(!inactive)} className={`card  my-4 c-shadow2 ${inactive ? 'c-shadow card-border' : ''}`}>
                <div className="card-body bg-card text-center">
                  <img src={CopyEditing} className='my-5' alt="proofreading" />
                  <h4 className='pb-5'>Copy Editing</h4>
                </div>
              </div>
              </div>
            </div>
            <div className='d-flex justify-content-center align-items-center my-5'>
              <Link to='/upload-document' className='btn btn-primary shadow'>Proceed to Upload Document</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseService
