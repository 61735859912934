import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Prompt } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./CreateOrders.css";

const userId = localStorage.getItem("userId");


const CreateOrders = (props) => {
  const service_id = props?.match?.params?.id;
  console.log("service_id  >>>>>>>>", service_id);
  const [selectedFile, setSelectedFile] = useState(document)
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false)
  const [buttonLoad, setbuttonLoad] = useState(false);
  const [pricing, setPricing] = useState([]);
  const [plan, setPlan] = useState("");
  const [loadings, setLoadings] = useState(false);
  // const [currency, setCurrrency] = useState("");
  const [prices, setPrices] = useState("");
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    currency: "",
    document: "",
    title: "",
    bibliography: "",
    footnotes: "",
    style_guide: "",
    field: "",
    english_version: "",
    service_id,
    user_id: userId,
  });


  const handleCount = (e) => {
    setLoadings(true)
    const data = new FormData();
      data.append("upload_preset", "write-right");
      data.append("currency", details.currency);
      data.append("file", selectedFile);
      data.append("plan_id", plan);
    setLoading(false);
    console.log(data);
    const baseURL = process.env.REACT_APP_BASEURL;

    axios
      .post(`${baseURL}/api/v1/pricing`, data, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        console.log(response.data);
        console.log(response.data.data.price);
        setPrices(response.data.data);
        setLoadings(false);
      });
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(details)
    setLoading(true)
    setLoadings(false)
    setbuttonLoad(true)
    const data = new FormData();
    data.append("document", selectedFile);
    data.append("upload_preset", "write-right");
    data.append("plan_id", plan);
    data.append("currency", details.currency);
    data.append("title", details.title);
    data.append("bibliography", details.bibliography);
    data.append("footnotes", details.footnotes);
    data.append("style_guide", details.style_guide);
    data.append("field", details.field);
    data.append("english_version", details.english_version);
    data.append("service_id", service_id);
    if (userId) {
      data.append("user_id", userId)
    } else {
      data.append("first_name", details.first_name);
      data.append("last_name", details.last_name);
      data.append("email", details.email);
      data.append("phone", details.phone);
    }



    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .post(
        `${baseURL}/api/v1/orders`, data,
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
          'Content-Type': 'multipart/form-data',
        }
      )
      .then((response) => {
        console.log(response.data);
        console.log(response.data.data.link)
        toast.success(response.data.message)
        console.log(response.data.data.price);
        setbuttonLoad(false)
        if (response.data.status === "success") {
          setTimeout(() => {
            window.location = response.data.data.link
          }, 3000);
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false);
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          toast.error(error.response.data.message);
          setErrors(error.response.data.errors);
          setbuttonLoad(false)
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setErrors(error.response.data.errors);
          setbuttonLoad(false)
        }
      })
  }

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/plans`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setPricing(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // <Prompt
  //   // when={shouldBlockNavigation}
  //   message="You have unsaved changes, are you sure you want to leave?"
  // />;

  return (
    <div className="create-orders" id="order">
      <ToastContainer />
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <p>
                Please use this page to upload your document file for us to work
                on. Once you have uploaded all your documents and given us your
                instructions, use the 'Proceed to Checkout' button at the bottom
                of the page to proceed to our secure checkout and send us your
                order online.
              </p>
              <div className="card mb-5">
                <div className="card-body">
                  <p className="head">
                    <i className="bi bi-file-earmark-fill"></i> Your Document(s)
                  </p>
                  <p className="text-center">
                    To add files to your order, click the{" "}
                    <span className="text-primary">'Choose File'</span> button
                    below.
                  </p>
                  <div className="row center">
                    <div className="col-md-5">
                      <input
                        required
                        type="file"
                        className="form-control text-primary btn-light"
                        name="file"
                        onChange={(e) => {
                          setSelectedFile(e.target.files[0]);
                          if (details.currency !== "" && plan !== "") {
                            handleCount(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="m-3 center">
                    <label htmlFor="" className="form-label mx-3">
                      <span className="text-danger">*</span> Select Currency
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="currency"
                        name="currency"
                        value={details.currency}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            currency: e.target.value,
                          });
                        }}
                        onClick={(e) => {
                          handleCount(e);
                        }}
                      >
                        <option defaultValue="1">--Select--</option>
                        <option value="NGN">Naira (NGN)</option>
                        <option value="USD">Dollar (USD)</option>
                      </select>
                    </div>
                  </div>
                  <div className="m-3 center">
                    <label htmlFor="" className="form-label mx-3">
                      <span className="text-danger">*</span> Select Urgency
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="currency"
                        name="currency"
                        value={plan}
                        onChange={(e) => {
                          setPlan(e.target.value);
                        }}
                        onClick={(e) => {
                          if (details.currency && selectedFile) {
                            handleCount(e);
                          }
                        }}
                      >
                        <option defaultValue="1">--Select--</option>
                        {pricing?.map((price) => {
                          const { id, uuid, urgency } = price;
                          return (
                            <option key={id} value={uuid}>
                              {urgency}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {userId ? (
                ""
              ) : (
                <div className="card mb-5">
                  <div className="card-body">
                    <p className="head">
                      <i className="bi bi-person-fill"></i> Details
                    </p>
                    <div className="row mx-5">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="first_name" className="form-label">
                            <span className="text-danger">*</span> First Name
                          </label>
                          <input
                            type="text"
                            required
                            className="form-control mb-3"
                            placeholder="John"
                            id="first_name"
                            name="first_name"
                            value={details.first_name}
                            onChange={(e) =>
                              setDetails({
                                ...details,
                                first_name: e.target.value,
                              })
                            }
                          />
                          <p className="text-danger">{errors.first_name}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="last_name">
                            <span className="text-danger">*</span> Last Name
                          </label>
                          <input
                            type="text"
                            required
                            className="form-control mb-3"
                            placeholder="Doe"
                            id="last_name"
                            name="last_name"
                            value={details.last_name}
                            onChange={(e) =>
                              setDetails({
                                ...details,
                                last_name: e.target.value,
                              })
                            }
                          />
                          <p className="text-danger">{errors.last_name}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">
                            <span className="text-danger">*</span> Phone
                          </label>
                          <input
                            type="tel"
                            required
                            className="form-control mb-3"
                            placeholder="+2347023483595"
                            id="phone"
                            name="phone"
                            value={details.phone}
                            onChange={(e) =>
                              setDetails({ ...details, phone: e.target.value })
                            }
                          />
                          <p className="text-danger">{errors.phone}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">
                            <span className="text-danger">*</span> Email
                          </label>
                          <input
                            type="email"
                            required
                            className="form-control mb-3"
                            placeholder="johndoe@yahoo.com"
                            id="email"
                            name="email"
                            value={details.email}
                            onChange={(e) =>
                              setDetails({ ...details, email: e.target.value })
                            }
                          />
                          <p className="text-danger">{errors.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="card mb-5">
                <div className="card-body">
                  <p className="head">
                    <i className="bi bi-grid-3x3-gap-fill"></i> Instructions
                  </p>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      <span className="text-danger">*</span> Please enter a
                      title for your order:
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control mb-3"
                      id="title"
                      name="title"
                      value={details.title}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          title: e.target.value,
                        })
                      }
                    />
                    <p className="text-danger">{errors.title}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      <span className="text-danger">*</span> Do you want us to
                      edit or proofread your bibliography/references?
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="bibliography"
                        name="bibliography"
                        value={details.bibliography}
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            bibliography: e.target.value,
                          })
                        }
                      >
                        <option defaultValue="1">Select from:</option>
                        <option value="1">
                          Yes and I will tell you my style guide
                        </option>
                        <option value="0">No Thank you</option>
                      </select>
                    </div>
                    <p className="text-danger">{errors.bibliography}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      <span className="text-danger">*</span> Do you want us to
                      edit or proofread your endnotes and/or footnotes?
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="footnotes"
                        name="footnotes"
                        value={details.footnotes}
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            footnotes: e.target.value,
                          })
                        }
                      >
                        <option defaultValue="1">Select from:</option>
                        <option value="1">
                          Yes and I will tell you my style guide below
                        </option>
                        <option value="0">No Thank you</option>
                      </select>
                      {/* {error.footnotes} */}
                    </div>
                    <p className="text-danger">{errors.footnotes}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      <span className="text-danger">*</span> If you need to
                      follow a specific style guide (such as APA, Chicago, MLA,
                      or Harvard) or the guidelines of a specific journal,
                      please tell us the style guide or journal. NOTE: If you
                      need to use Harvard, please tell us the name of your
                      school.
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="style_guide"
                        name="style_guide"
                        value={details.style_guide}
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            style_guide: e.target.value,
                          })
                        }
                      >
                        <option defaultValue="1">Select from:</option>
                        <option value="I don't know. Please just make it consistent">
                          I don't know. Please just make it consistent
                        </option>
                        <option value="I don't need references checked">
                          I don't need references checked
                        </option>
                        <option value="A specific Academic journal (I will list it below)">
                          A specific Academic journal (I will list it below)
                        </option>
                        <option value="APA 7th Edition">APA 7th Edition</option>
                        <option value="Bluebook">Bluebook</option>
                        <option value="Chicago Manual 17th Ed.">
                          Chicago Manual 17th Ed.
                        </option>
                        <option value="Havard">Havard</option>
                        <option value="IEEE">IEEE</option>
                        <option value="MLA">MLA</option>
                        <option value="OSCOLA">OSCOLA</option>
                        <option value="Turabian">Turabian</option>
                        <option value="Vancouver">Vancouver</option>
                      </select>
                    </div>
                    <p className="text-danger">{errors.style_guide}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      <span className="text-danger">*</span> Is this document
                      scientific/technical or arts/humanities/social sciences:
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="field"
                        name="field"
                        value={details.field}
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            field: e.target.value,
                          })
                        }
                      >
                        <option defaultValue="1">Select from:</option>
                        <option value="Scientific/Technical/Medical">
                          Scientific/Technical/Medical
                        </option>
                        <option value="Art/Humanities/Social Sciences">
                          Art/Humanities/Social Sciences
                        </option>
                        <option value="Others">Not Sure</option>
                      </select>
                    </div>
                    <p className="text-danger">{errors.field}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      <span className="text-danger">*</span> Which version of
                      English should be used when editing or proofreading your
                      document:
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="english_version"
                        name="english_version"
                        value={details.english_version}
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            english_version: e.target.value,
                          })
                        }
                      >
                        <option defaultValue="1">Select from:</option>
                        <option value="US">US</option>
                        <option value="Britain">Britain</option>
                        <option value="Canadian">Canadian</option>
                        <option value="Australian">Australian</option>
                      </select>
                    </div>
                    <p className="text-danger">{errors.english_version}</p>
                  </div>
                </div>
              </div>
              <div className="card mb-5">
                <div className="card-body">
                  <p className="head">
                    <i className="bi bi-cart-fill"></i> Order Total
                  </p>
                  <hr />
                  Currency:
                  {/* {currency === "USD"
                    ? "US Dollar"
                    : "NGN Naira"}{" "} */}
                  <span className="float-end">
                    <strong>
                      {loadings ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <h5 className="">
                          {/* <CurrencyFormat
                            value={prices?.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={currency === "USD" ? "$" : "₦"}
                          /> */}
                          {prices?.price}
                        </h5>
                      )}
                    </strong>
                  </span>
                  <hr />
                  <div className="float-end">
                    <button
                      type="submit"
                      className="btn btn-primary shadow mb-2"
                      disabled={loading}
                    >
                      Proceed to Checkout
                      {buttonLoad && (
                        <div
                          className="spinner-border text-light mx-3"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrders;
