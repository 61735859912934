import React from "react";
import { InlineWidget } from "react-calendly";

const calendly = process.env.REACT_APP_CALENDLY;

const ThirdAssessment = () => {

  function isCalendlyEvent(e) {
  return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
};
 
window.addEventListener("message", function(e) {
  if(isCalendlyEvent(e)) {
    /* Example to get the name of the event */
    console.log("Event name:", e.data.event);
    
    /* Example to get the payload of the event */
    console.log("Event details:", e.data.payload);
  }
});

  return (
    <div className="container">
      <div className="row">
        <div className="card border-0 my-5">
          <div className="card-body">
            <div className="calendly">
              <InlineWidget
                prefill={{
                  email: "",
                  firstName: "",
                  lastName: "",
                  name: "",
                }}
                url={`https://calendly.com/${calendly}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdAssessment;
