import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const Editor = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [strengthenPassword, setStrengthenPassword] = useState(true);
  // const [emailCheck, setEmailCheck] = useState(true)
  const [redirect, setredirect] = useState('')
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    institution: "",
    course: "",
    company: "",
    role: "",
    password: "",
    password_confirmation: ""
  });

  const showPassword = () => {
    setPasswordShown(!passwordShown);
  }

  const checkStrength = () => {
    var lower_case = new RegExp("^(?=.*?[a-z])");

    var letters_capitals = new RegExp("^(?=.*?[A-Z])");

    var one_digit = new RegExp("^(?=.*?[0-9])");

    var special_char = new RegExp("^(?=.*?[#?!@$%^&*-])");

    if(!lower_case.test(details.password)) {
      setStrengthenPassword('Password should have at lease one lowercase')
    }
    else if (!letters_capitals.test(details.password)) {
      setStrengthenPassword("Password should have at least one uppercase");
    } else if (!one_digit.test(details.password)) {
      setStrengthenPassword("Password should contain at least one digit");
    } else if (!special_char.test(details.password)) {
      setStrengthenPassword("Password should contain one special character");
    } else if (details.password.length < 8) {
      setStrengthenPassword("Password should be more than 8 characters");
    } else {
      setStrengthenPassword("");
    }
  }
  const handleEvent = (e) => {
    e.preventDefault();
    if (details.password !== details.password_confirmation) {
      toast.error("Password do not match");
    } else {
      setLoading(true);
      axios.defaults.withCredentials = true;
      const baseURL = process.env.REACT_APP_BASEURL;
      const response = axios
        .get(`${baseURL}/sanctum/csrf-cookie`)
        .then((response) => {
          const baseURL = process.env.REACT_APP_BASEURL;
          return axios.post(`${baseURL}/api/v1/editors`, {
              first_name: details.first_name,
              last_name: details.last_name,
              email: details.email,
              phone: details.phone,
              password: details.password,
              password_confirmation: details.password_confirmation,
              academic_experience: [
                {
                  institution: details.institution,
                  course: details.course,
                },
              ],
              professional_experience: [
                {
                  company: details.company,
                  role: details.role,
                },
              ]
          }, {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          });
        })
        .then((res) => {
              toast.success(res.data.message);
              setLoading(false)
              if(res.data.status === 'success') {
                setTimeout(() => {
                  setredirect("/verify");
                }, 3000);
              }
              
            })
            .catch(error => {
              if (error.response.status === 422) {
                toast.error(error.response.data.message);
                setError(error.response.data.errors);
                setLoading(false);
              } else if (error.response.status === 400) {
                toast.error(error.response.data.message);
                setError(error.response.data.errors);
                setLoading(false);
              }
            })
      return response;
      
    }
  }




  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className="container">
      <ToastContainer autoClose={3000} />

      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-md-7">
          <div className="my-5 card w-100 border-0 shadow">
            <div className="card-body">
              <div className="form-head">
                <h3 className="text-center text-primary">Create Account</h3>
                <p className="text-center text-dark">
                  Start copywriting and proofreading documents for users
                </p>
              </div>
              <form className="form" onSubmit={handleEvent}>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="first_name">First name</label>
                    <input
                      type="text"
                      required
                      className="form-control mb-3"
                      placeholder="John"
                      id="first_name"
                      name="first_name"
                      value={details.first_name}
                      onChange={(e) =>
                        setDetails({ ...details, first_name: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="last_name">Last name</label>
                    <input
                      type="text"
                      required
                      className="form-control mb-3"
                      placeholder="Doe"
                      id="last_name"
                      name="last_name"
                      value={details.last_name}
                      onChange={(e) =>
                        setDetails({ ...details, last_name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  required
                  className="form-control mb-3"
                  placeholder="johndoe@yahoo.com"
                  id="email"
                  name="email"
                  value={details.email}
                  onChange={(e) =>
                    setDetails({ ...details, email: e.target.value })
                  }
                />
                <p className="text-danger">{error.email}</p>
                <label htmlFor="phone">Phone number</label>
                <input
                  type="tel"
                  required
                  className="form-control mb-3"
                  placeholder="+2347023483595"
                  id="phone"
                  name="phone"
                  value={details.phone}
                  onChange={(e) =>
                    setDetails({ ...details, phone: e.target.value })
                  }
                />
                <p className="text-danger">{error.phone}</p>
                {/* <h5 className="my-4">Academic Experience</h5>
                <label htmlFor="institution">Institution</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="e.g University of Ibadan"
                  id="institution"
                  name="institution"
                  value={details.institution}
                  onChange={(e) =>
                    setDetails({ ...details, institution: e.target.value })
                  }
                />
                <label htmlFor="course">Course of Study</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="e.g Linguistics"
                  id="course"
                  name="course"
                  value={details.course}
                  onChange={(e) =>
                    setDetails({ ...details, course: e.target.value })
                  }
                />
                <h5 className="my-4">Professional Experience</h5>
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="e.g Google"
                  id="company"
                  name="company"
                  value={details.company}
                  onChange={(e) =>
                    setDetails({ ...details, company: e.target.value })
                  }
                />
                <label htmlFor="role">Role</label>
                <input
                  type="text"
                  required
                  className="form-control mb-3"
                  placeholder="e.g Writer"
                  id="role"
                  name="role"
                  value={details.role}
                  onChange={(e) =>
                    setDetails({ ...details, role: e.target.value })
                  }
                /> */}
                <label htmlFor="password">Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  required
                  className="form-control mb-3"
                  placeholder="********"
                  id="password"
                  name="password"
                  value={details.password}
                  onChange={(e) =>
                    setDetails({ ...details, password: e.target.value })
                  }
                  onKeyUp={checkStrength}
                />
                <label htmlFor="passwordConfirm">Confirm Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  required
                  className="form-control mb-3"
                  placeholder="********"
                  id="password_confirmation"
                  name="confrimPassword"
                  value={details.password_confirmation}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      password_confirmation: e.target.value,
                    })
                  }
                />
                <p className="text-danger">{strengthenPassword}</p>
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onClick={showPassword}
                />
                <span className="mx-3">Show Password</span>
                <button
                  type="submit"
                  className="btn btn-primary w-100 shadow"
                  disabled={loading}
                >
                  create account
                  {loading && <i className="fa fa-refresh fa-spin mx-2"></i>}
                </button>
              </form>
              <p className="text-center text-dark">
                Already have an account? <Link to="/editor/login">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editor;
