import axios from "axios";
import React, { useEffect, useState } from "react";

import { Link, Redirect } from "react-router-dom";

import { toast } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";


const Deliverables = () => {
  const [redirect, setRedirect] = useState("");
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/deliverables`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setJobs(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.clear();
          setRedirect("/editor/login");
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <div className="row">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "200px" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : jobs?.length !== 0 ? (
              <div className="col-md-10">
                <div className="card border-0 shadow">
                  <div className="card-body">
                    <p className="head">
                      <i className="bi bi-clock-history mx-2"></i> Deliverables
                    </p>
                    <div className="row">
                      {jobs?.map((job) => {
                        const { id, uuid, editor, order, user } = job;
                        return (
                          <div className="row" key={id}>
                            <div className="row m-5">
                              <h5 className="mb-5">Order Information</h5>
                              <div className="row">
                                <div className="col-md-4">
                                  <p>
                                    <small>Title</small> <br />
                                    <strong>{order.title}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Bibliography</small> <br />
                                    <strong>{order.bibliography}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Englisih Version</small> <br />
                                    <strong>{order.english_version}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Field</small> <br />
                                    <strong>{order.field}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Number of words</small> <br />
                                    <strong>{order.number_of_words}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Englisih Version</small> <br />
                                    <strong>{order.english_version}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Footnotes</small> <br />
                                    <strong>{order.footnotes}</strong>
                                  </p>
                                </div>
                              </div>

                              <h5 className="my-5">Editor Information</h5>
                              <div className="row">
                                <div className="col-md-4">
                                  <p>
                                    <small>First name</small> <br />
                                    <strong>{editor.first_name}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Last name</small> <br />
                                    <strong>{editor.last_name}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Email</small> <br />
                                    <strong>{editor.email}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Phone number</small> <br />
                                    <strong>{editor.phone}</strong>
                                  </p>
                                </div>
                              </div>

                              <h5 className="my-5">Owner Information</h5>
                              <div className="row">
                                <div className="col-md-4">
                                  <p>
                                    <small>First name</small> <br />
                                    <strong>{user.first_name}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Last name</small> <br />
                                    <strong>{user.last_name}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Email</small> <br />
                                    <strong>{user.email}</strong>
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p>
                                    <small>Phone number</small> <br />
                                    <strong>{user.phone}</strong>
                                  </p>
                                </div>
                              </div>

                              <Link to={"/editor/show-deliverables/" + uuid}>
                                <span className="details">See Document</span>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12 mt-5">
                <div className="card w-100">
                  <div className="card-body p-0">
                    <p className="head">
                      <i className="bi bi-clock-history mx-2"></i> Deliverables
                    </p>
                    <div className="container mt-4"></div>
                    <div className="container">
                      <div className="row">
                        <div className="alert alert-danger p-2" role="alert">
                          <span>
                            {" "}
                            <i className="bi bi-exclamation-triangle"></i> There
                            are no deliverables at the moment
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliverables;
