import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from '../Sidebar/Sidebar';
import './Users.css'

const Users = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/users`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setUsers(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row bg-white">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="top"></div>
            <div className="card border-0">
              <div className="card-body">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <div className="float-end">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <table className="table table-borderless table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">FIRST NAME</th>
                          <th scope="col">LAST NAME</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col">PHONE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users
                          .filter((val) => {
                            if (search === "") {
                              return val;
                            } else if (
                              val.first_name
                                .toLowerCase()
                                .includes(search.toLocaleLowerCase())
                            ) {
                              return val;
                            } else if (
                              val.last_name
                                .toLowerCase()
                                .includes(search?.toLocaleLowerCase())
                            ) {
                              return val;
                            } else if (
                              val.email
                                .toLowerCase()
                                .includes(search?.toLocaleLowerCase())
                            ) {
                              
                            }
                            return val;
                          })
                          .map((user, index) => {
                            const {
                              id,
                              first_name,
                              last_name,
                              email,
                              phone,
                              uuid,
                            } = user;
                            return (
                              <tr
                                key={id}
                                onClick={() =>
                                  setRedirect("/admin/user-details/" + uuid)
                                }
                              >
                                <th scope="row">{index + 1}</th>
                                <td>{first_name}</td>
                                <td>{last_name}</td>
                                <td>{email}</td>
                                <td>{phone}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users