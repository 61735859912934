import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";
import "./Users.css";

const UserDetails = (props) => {
  const userId = props?.match?.params?.id;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/users/${userId}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setUsers(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, [userId]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row feture-tabs bg-primary">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="top"></div>
            <ul className="nav nav-pills mb-3">
              <li>
                <a
                  className="nav-link active"
                  data-bs-toggle="pill"
                  href="#tab1"
                >
                  User Details
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab2">
                  Orders
                </a>
              </li>
              <li>
                <a className="nav-link" data-bs-toggle="pill" href="#tab3">
                  Payments
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab1">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>User Details</h4>
                            <div className="mx-4">
                              <p>
                                FIRST NAME <br />
                                <span className="user-details">
                                  {users?.first_name}
                                </span>
                              </p>
                              <p>
                                LAST NAME <br />
                                <span className="user-details">
                                  {users?.last_name}
                                </span>
                              </p>
                              <p>
                                EMAIL <br />
                                <span className="user-details">
                                  {users?.email}
                                </span>
                              </p>
                              <p>
                                PHONE <br />
                                <span className="user-details">
                                  {users?.phone}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane fade show" id="tab2">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>Orders</h4>
                            {users.orders.length !== 0 ? (
                              <div className="table-responsive">
                                <table className="table responsive table-hover table-borderless mt-2 mb-0">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>TITLE</th>
                                      <th>CATEGORY</th>
                                      <th>DESCRIPTION</th>
                                      <th>URGENCY</th>
                                      <th>WORDS</th>
                                      <th>PRICE</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {users.orders.map((order, index) => {
                                      const {
                                        id,
                                        number_of_words,
                                        price,
                                        currency,
                                        title,
                                        urgency,
                                        document,
                                        service,
                                        uuid,
                                      } = order;
                                      return (
                                        <tr
                                          onClick={() =>
                                            setRedirect(
                                              "/admin/order-details/" + uuid
                                            )
                                          }
                                          key={id}
                                        >
                                          <th scope="row">{index + 1}</th>
                                          <td>{title}</td>
                                          <td>{service?.name}</td>
                                          <td>{service?.description}</td>
                                          <td>{urgency}</td>
                                          <td>{number_of_words}</td>
                                          <td>
                                            {currency} {price}
                                          </td>
                                          <td>
                                            <a
                                              className="badge rounded-pill bg-primary"
                                              href={document}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              download
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <div className="container">
                                <div className="row">
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    <span>
                                      {" "}
                                      <i className="bi bi-exclamation-triangle"></i>{" "}
                                      No orders yet
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tab-pane fade show" id="tab3">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "200px" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card border-0 shadow">
                          <div className="card-body">
                            <h4>Payments</h4>
                            <div className="mx-4">
                              {users.payments.length !== 0 ? (
                                <div className="table-responsive">
                                  <table className="table responsive table-hover mb-5">
                                    <thead>
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">PROVIDER</th>
                                        <th scope="col">REFERENCE</th>
                                        <th scope="col">DATE</th>
                                        <th scope="col">STATUS</th>
                                        <th scope="col">AMOUNT</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading ? (
                                        <div
                                          className="d-flex justify-content-center align-items-center"
                                          style={{ marginTop: "200px" }}
                                        >
                                          <div
                                            className="spinner-border text-primary"
                                            role="status"
                                          >
                                            <span className="sr-only"></span>
                                          </div>
                                        </div>
                                      ) : (
                                        users.payments.map((payment, index) => {
                                          const {
                                            id,
                                            provider,
                                            reference,
                                            amount,
                                            created_at,
                                            currency,
                                            status,
                                          } = payment;
                                          return (
                                            <tr key={id}>
                                              <th scope="row">{index + 1}</th>
                                              <td>{provider}</td>
                                              <td>{reference}</td>
                                              <td>{created_at}</td>
                                              <td
                                                style={{
                                                  color:
                                                    status === "successful"
                                                      ? "#00E324"
                                                      : status ===
                                                        "unsuccessful"
                                                      ? "#E33700"
                                                      : "#E3B100",
                                                }}
                                              >
                                                {status}
                                              </td>
                                              <td>
                                                {currency} {amount}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="container">
                                  <div className="row">
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      <span>
                                        {" "}
                                        <i className="bi bi-exclamation-triangle"></i>{" "}
                                        No payments
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
