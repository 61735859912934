import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";
import "../Users/Users.css";

const Editors = () => {
  const [editors, setEditors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");
  const [active, setActive] = useState("");
  const [search, setSearch] = useState("");


  const allEditors = () => {
    setLoading(true);
    setActive("");
    setLoading(false);
  };

  const activeEditors = () => {
    setLoading(true)
    setActive('?is_active=1')
    setLoading(false);
  }
  const inactiveEditors = () => {
    setLoading(true);
    setActive("?is_active=0");
    setLoading(false);
  };
  const internalEditors = () => {
    setLoading(true);
    setActive("/internal-editors");
    setLoading(false);
  };
  const bannedEditors = () => {
    setLoading(true);
    setActive("/banned");
    setLoading(false);
  };

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/editors${active}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setEditors(response.data.data);
        setLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, [active]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row bg-white">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="top"></div>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "200px" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <div className="card border-0 shadow">
                  <div className="card-body">
                    <div className="row feture-tabs" data-aos="fade-up">
                      <div className="col-lg-12">
                        <ul className="nav nav-pills mb-3">
                          <li onClick={allEditors}>
                            <a
                              className="nav-link active"
                              data-bs-toggle="pill"
                              href="#tab1"
                            >
                              All Editors
                            </a>
                          </li>
                          <li onClick={activeEditors}>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab2"
                            >
                              Active Editors
                            </a>
                          </li>
                          <li onClick={inactiveEditors}>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab3"
                            >
                              Inactive Editors
                            </a>
                          </li>
                          <li onClick={internalEditors}>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab4"
                            >
                              Internal Editors
                            </a>
                          </li>
                          <li onClick={bannedEditors}>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab4"
                            >
                              Banned Editors
                            </a>
                          </li>
                        </ul>
                        <div className="float-end">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                        <div className="tab-content">
                          <div className="tab-pane fade show active" id="tab1">
                            <h4>All Editors</h4>
                          </div>

                          <div className="tab-pane fade show" id="tab2">
                            <h4>Active Editors</h4>
                          </div>

                          <div className="tab-pane fade show" id="tab3">
                            <h4>Inactive Editors</h4>
                          </div>

                          <div className="tab-pane fade show" id="tab4">
                            <h4>Internal Editors</h4>
                          </div>

                          <div className="tab-pane fade show" id="tab5">
                            <h4>Banned Editors</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table className="table table-borderless table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">FIRST NAME</th>
                          <th scope="col">LAST NAME</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col">PHONE</th>
                          <th scope="col">STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {editors
                          .filter((val) => {
                            if (search === "") {
                              return val;
                            } else if (
                              val.first_name
                                .toLowerCase()
                                .includes(search.toLocaleLowerCase())
                            ) {
                              return val;
                            } else if (
                              val.last_name
                                .toLowerCase()
                                .includes(search?.toLocaleLowerCase())
                            ) {
                              return val;
                            } else if (
                              val.email
                                .toLowerCase()
                                .includes(search?.toLocaleLowerCase())
                            ) {
                              
                            }
                            return val;
                          })
                          .map((editor, index) => {
                            const {
                              id,
                              first_name,
                              last_name,
                              email,
                              phone,
                              uuid,
                              status
                            } = editor;
                            return (
                              <tr
                                key={id}
                                onClick={() =>
                                  setRedirect("/admin/editor-details/" + uuid)
                                }
                              >
                                <th scope="row">{index + 1}</th>
                                <td>{first_name}</td>
                                <td>{last_name}</td>
                                <td>{email}</td>
                                <td>{phone}</td>
                                <td
                                  style={{
                                    color:
                                      status === "active"
                                        ? "#00E324"
                                        : status === "inactive"
                                        ? "#E33700"
                                        : "#E3B100",
                                  }}
                                >
                                  {status}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editors;
