import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import "./Esl.css";


const Esl = (props) => {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    const service_id = props?.match?.params?.id;
    console.log(service_id);
    localStorage.setItem("service_id", service_id);
    axios
      .get(`${baseURL}/api/v1/categories/${props?.match?.params?.id}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setCategory(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      });
  }, [props?.match?.params?.id]);
  return (
    <div className="esl">
      <div className="esl-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="esl-head">
                <i className="bi bi-globe"></i>
                {category?.name}
              </h2>
              <h5 className="esl-point">Editing and Proofreading Services</h5>
              <p className="esl-desc">{category?.description}</p>
              <a href="#service" className="btn btn-outline-warning">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="my-5">{category?.name}</h3>
              <p className="">{category?.description}</p>
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12" id="service">
                {category.id === 1 ? (
                  <div className="col-md-12">
                    <h3 className="py-3">What you will receive</h3>
                    <ul>
                      <li className="lists">
                        Tracked revision of your work so you can see changes and
                        comments on your work
                      </li>
                      <li className="lists">
                        An edited clean version with the changes already
                        embedded.
                      </li>
                      <li className="lists">
                        Short observations and major concerns
                      </li>
                      <li className="lists">
                        Formatting of citations and references (If desired)
                      </li>
                    </ul>
                    <p>
                      Be assured that your manuscript will be kept confidential.
                      Uploads and downloads to our server are securely
                      encrypted. We have strict privacy policies and handle most
                      major file types. We respect your privacy.
                    </p>
                  </div>
                ) : category.id === 2 ? (
                  <div className="col-md-12">
                    <h3 className="py-3">What you will receive</h3>
                    <ul>
                      <li className="lists">
                        Tracked revision of your work so you can see changes and
                        comments on your work
                      </li>
                      <li className="lists">
                        An edited clean version with the changes already
                        embedded.
                      </li>
                      <li className="lists">
                        Corrections of spelling, grammatical errors, typos and
                        inconsistencies; improved clarity, organizational flow;
                        stronger authorial voice; improved tone, style, point of
                        view, word choice and sentence structure
                      </li>
                      <li className="lists">
                        Extensive critical commentaries and editors notes
                      </li>
                    </ul>
                    <p>
                      <span className="delivery">
                        Your copyright remains yours exclusively!
                      </span>{" "}
                      We will never make a claim on your copyright. Be assured
                      that your manuscript will be kept confidential. Uploads
                      and downloads to our server are securely encrypted. We
                      have strict privacy policies and handle most major file
                      types. We respect your privacy. We will keep your files
                      secure.
                    </p>
                  </div>
                ) : category.id === 4 ? (
                  <div className="col-md-12">
                    <h3 className="py-3">
                      We offer training on accent polishing for:
                    </h3>
                    <ul>
                      <li className="lists">Pastors</li>
                      <li className="lists">Actors/actresses</li>
                      <li className="lists">Public Speakers</li>
                      <li className="lists">Motivational Speakers</li>
                      <li className="lists">Teachers</li>
                      <li className="lists">Individuals</li>
                      <li className="lists">Groups</li>
                      <li className="lists">Students</li>
                      <li className="lists">
                        Political figures and Dignitaries
                      </li>
                      <li className="lists">Professionals</li>
                      <li className="lists">Corporate clients</li>
                    </ul>
                  </div>
                ) : category.id === 6 ? (
                  <div className="col-md-12">
                    <p>
                      Your professors or employers may be too busy to craft a
                      professional Letter of Recommendation for you. We will
                      write on their behalf as we know:
                    </p>
                    <ul>
                      <li>• What to include or not</li>
                      <li>• The suitable length</li>
                      <li>
                        • How to connect your relationship with the referrer and{" "}
                      </li>
                      <li>• Ways to properly highlight your competencies</li>
                    </ul>
                    <p>
                      You just get them signed, our professional touch will make
                      you stand out among the crowd!
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <h3 className="my-5">
                Select a service to get an instant quote.
              </h3>
              {category?.services?.map((service) => {
                const { id, name, uuid } = service;
                return (
                  <div className="col-md-4 mb-4" key={id}>
                    <Link to={"/place-order/" + uuid}>
                      <div className="card esl-card">
                        <div className="card-body">
                          <h4>{name}</h4>
                          <p className="esl-service">{category.description}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Esl;
