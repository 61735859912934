import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../CompletedOrders/CompletedOrders.css";

const Drafts = () => {
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/categories`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="container my-5">
      <div className="row my">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body m-0 p-0">
              <div className="menu-items">
                <ul>
                  <li>
                    <Link to="/completed-orders" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-check2-square mx-2"></i> Completed
                          Orders
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/in-progress" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-arrow-counterclockwise mx-2"></i>{" "}
                          In progress
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/order-history" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-clock-history mx-2"></i> Order
                          History
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="inactive">
                    <Link to="/draft" className="side-item">
                      <div className="menu-item">
                        <span className="text-primary">
                          <i className="bi bi-journal-check mx-2"></i> Drafts
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-profile" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-arrow-counterclockwise m-2"></i>{" "}
                          Account Settings
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className=" my-5 d-flex justify-content-center align-items-center">
                <a href="/#service" className="btn btn-primary shadow">
                  New Order
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card w-100">
            <div className="card-body p-0">
              <p className="head">
                <i className="bi bi-journal-check mx-2"></i> Drafts
              </p>
              <div className="container">
                <div className="row">
                  <div className="alert alert-danger p-2" role="alert">
                    <span>
                      {" "}
                      <i className="bi bi-exclamation-triangle"></i> Your drafts folder is currently empty.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drafts;
