import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import "./Home.css";
import WriteRight from "../../images/write-right.png";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import TypeAnimation from 'react-type-animation'
import dasylva from '../../images/Dasylva.png'

const Home = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/categories`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setServices(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="container mt-5">
        <div className="row d-flex ">
          <div className="col-md-6 mt-5">
            <h2 className="header">
              Editing and Proofreading for <br />
              <span className="text-warning">
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    "Academic Editing",
                    3000,
                    clearInterval(1000),
                    "Book Editing",
                    3000,
                    clearInterval(1000),
                    "Transcribing Services",
                    3000,
                    clearInterval(1000),
                    "Accent Training",
                    3000,
                    clearInterval(1000),
                    "Statement of Purpose",
                    3000,
                    clearInterval(1000),
                    "Letters of Recommendation",
                    3000,
                    clearInterval(1000),
                    "You",
                    3000,
                    clearInterval(1000),
                  ]}
                  wrapper="h2"
                  repeat={Infinity}
                />
              </span>
            </h2>
            <h5 className="text-primary">Fast, Affordable, Professional</h5>
            <p className="header-para">
              Write Right is an editing, transcribing and accent polishing
              company that assists students, individuals, corporate bodies
              proofread, edit and re-write their books and theses and other
              publications.
            </p>
            <a
              href="/#service"
              className="btn btn-primary py-2 px-5 mb-5 shadow"
              style={{ fontSize: "20px" }}
            >
              Create Order
            </a>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid rounded header-image mb-5"
              src="https://img.freepik.com/free-photo/indoor-image-beautiful-young-dark-skinned-female-with-curly-hair-writing-down-copybook-making-plans-day-while-sitting-desk-with-cup-coffee-front-open-portable-computer_343059-4727.jpg?size=626&ext=jpg&ga=GA1.2.1549378347.1657094287"
              alt="write-right"
            />
          </div>
        </div>
      </div>
      <section className="bg-white" id="service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center service-head">
                Ordering is easy. Start by telling us what you want.
              </h2>
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <div className="row pb-5">
              {services?.map((service) => {
                const { id, name, description, uuid } = service;
                return (
                  <div key={id} className="col-md-4 text-center mb-5">
                    <Link
                      to={"/esl/" + uuid}
                      className="service-stat"
                      style={{ color: "#544baa" }}
                    >
                      {id === 1 ? (
                        <i
                          className="bi bi-mortarboard service-icon"
                          style={{ color: "#ff7900" }}
                        ></i>
                      ) : id === 2 ? (
                        <i
                          className="bi bi-people service-icon"
                          style={{ color: "#26a69a" }}
                        ></i>
                      ) : id === 3 ? (
                        <i
                          className="bi bi-book service-icon"
                          style={{ color: "#544baa" }}
                        ></i>
                      ) : id === 4 ? (
                        <i
                          className="bi bi-briefcase service-icon"
                          style={{ color: "#ef5350" }}
                        ></i>
                      ) : id === 5 ? (
                        <i
                          className="bi bi-person service-icon"
                          style={{ color: "#a1005b" }}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-card-text service-icon"
                          style={{ color: "#428bca" }}
                        ></i>
                      )}
                      <h5 className="service-main">{name}</h5>
                      <p className="service-desc">{description}</p>
                      <p
                        style={{
                          color:
                            id === 1
                              ? "#ff7900"
                              : id === 2
                              ? "#26a69a"
                              : id === 3
                              ? "#544baa"
                              : id === 4
                              ? "#ef5350"
                              : id === 5
                              ? "#a1005b"
                              : "#428bca",
                        }}
                      >
                        {name}
                      </p>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
      <section className="py-5">
      <div className="container">
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3 className="text-center">New Book Release</h3>
            </div>
            <div className="col-md-6">
              <img
              className="img-thumbnail mb-5"
              src={dasylva}
              alt="write-right"
            />
            <p>
            <a href="https://res.cloudinary.com/writeright/image/upload/v1666877351/Write%20Right/DASYLVA_COLLECTIVE_INTERVIEW_FINAL_2_n2xq7a.pdf" className="btn btn-primary" target="_blank" rel="noreferrer">View Book</a>
            </p>
            </div>
            <div className="col-md-6">
              <h2>Conversion With Prof. Ademola Omobewaji Dasylva</h2>
              <p>Professor Ademola Omobewaji Dasylva has spent approximately
                  half of his lifetime as a lecturer in the Nigerian University system.
                  His close involvement in the tertiary education sector, it could be
                  said, took off from a modest to what could by insight now be
                  described as an auspicious start in 1986. This was the year he started
                  work as a lecturer at the foremost college of education in Nigeria,
                  the Adeyemi College of Education (ACE) in Ondo. He had had a
                  stint in Ile-Ife teaching literature and related subjects at the Oyo
                  State College of Arts and Science, a production mill of sorts for
                  secondary school leavers studying to obtain A-Level qualifications
                  and certainly one of the best-known grooming grounds for aspiring
                  university undergraduates, then in the old Oyo State, if not the entire
                  south-west. Given his long connection to this organic world of the
                  mind, it should not be entirely unexpected that Prof. Dasylva is best
                  known for his scholarship and scholarly activities. He has
                  appropriately risen through the ranks as a teacher, from OSCAS
                  throughACE, to the enviable position of a professor in the country’s
                  premier university, the University of Ibadan</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
