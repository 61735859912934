import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { EditorId } from '../includes/Navbar';

const EditorAssessments = () => {
  const [loading, setLoading] = useState(true)
  const [test, setTest] = useState([])
  const [redirect, setRedirect] = useState('')

  useEffect(() => {
    console.log(EditorId);
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/editors/${EditorId}/tests`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        console.log(response.data.data);
        setTest(response.data.data)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        } else if (error.response.status === 403) {
          setRedirect("/editor/feeds");
        }
      })
  }, []);

  if(redirect) {
    return <Redirect to={redirect} />
  }
  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-item-center">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "300px" }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <div className="col-md-10 mt-5">
            <div className="card w-100 border-0 shadow">
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 mt-5">
                      <h4 className="text-center">
                        Welcome to the editor Dashboard
                      </h4>
                      <p className="text-center text-dark">
                        You will need to take three (3) assessments before you can be an editor. The first test will be multiple choice questions, the second one will be an essay and the last on will be a video interview 
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center align-items-center">
                    {test.map((list) => {
                      const { id, title, type, percent, total, uuid } = list;
                      const test_id = uuid;
                      console.log(test_id);
                      return (
                        <div key={id} className="col-md-4 my-4 text-center">
                          <h5>{title}</h5>
                          <p className="text-dark">
                            <strong>Type:</strong> {type}
                          </p>
                          <p className="text-dark">
                            <strong>Pass Mark:</strong> {percent}%
                          </p>
                          <p className="text-dark">
                            <strong>Total Score:</strong> {total}%
                          </p>
                          <div className="mb-5 d-flex justify-content-center align-items-center">
                            <Link
                              to={
                                id === 1
                                  ? "/assessment-one/" + uuid
                                  : id === 2
                                  ? "/assessment-two/" + uuid
                                  : "/assessment-three/" + uuid
                              }
                              className="btn btn-primary"
                            >
                              proceed to test
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditorAssessments
