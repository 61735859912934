import React, { useState } from 'react'
import './Sidebar.css'
import Logo from '../images/logo.png'
import { Link } from 'react-router-dom';


const Sidebar = () => {
  const [inactive, setInactive] = useState(false);
  return (

              <>
              <div className={`sidebar mt-5 ${inactive ? 'inactive' : ''}`}>
                <div className="top-section">
                  <div className="logo">
                    <img src={Logo} alt="logo" />
                  </div>
                  <div onClick={() => setInactive(!inactive)} className='toggle-menu-btn'>
                    {inactive ? (
                    <i className="bi bi-arrow-right-square-fill"></i>
                    )
                  : (
                  <i className="bi bi-arrow-left-square-fill"></i>
                  )
                    }
                  </div>
                </div>
                <div className="search-controller">
                  <button className='search-btn'>
                    <i className="bi bi-search"></i>
                  </button>
                  <input type="text" placeholder='Search' />
                </div>

                <div className="divider"></div>

                <div className="main-menu">
                  <ul>
                    <li onClick={() => setInactive(!inactive)}>
                      <Link to='/sidebar' className="side-item">
                        <div className="menu-icon">
                          <i className="bi bi-check2-square"></i>
                        </div>
                        <span>Completed Orders</span>
                      </Link>
                    </li>
                    <li onClick={() => setInactive(!inactive)}>
                      <Link to='/' className="side-item">
                        <div className="menu-icon">
                          <i className="bi bi-arrow-counterclockwise"></i>
                        </div>
                        <span>In progress</span>
                      </Link>
                    </li>
                    <li onClick={() => setInactive(!inactive)}>
                      <Link to='/' className="side-item">
                        <div className="menu-icon">
                          <i className="bi bi-clock-history"></i>
                        </div>
                        <span>Order History</span>
                      </Link>
                    </li>
                    <li onClick={() => setInactive(!inactive)}>
                      <Link to='/' className="side-item">
                        <div className="menu-icon">
                          <i className="bi bi-journal-check"></i>
                        </div>
                        <span>Drafts</span>
                      </Link>
                    </li>
                    <li onClick={() => setInactive(!inactive)}>
                      <Link to='/' className="side-item">
                        <div className="menu-icon">
                          <i className="bi bi-gear"></i>
                        </div>
                        <span>Account Settings</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-9">
                    <div className="main-section">
                    <div className="card">
                      <div className="card-body">
                        <h2>This is it</h2>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              </>
              

  )
}

export default Sidebar
