import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Redirect } from "react-router-dom";

const queryParams = new URLSearchParams(window.location.search);
console.log(window.location.search);
const user_id = queryParams.get("id");

const CreatePassword = () => {
  const [password, setPassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setredirect] = useState("");
  const [strengthenPassword, setStrengthenPassword] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errors, setErrors] = useState("");

  const showPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const checkStrength = () => {
    var lower_case = new RegExp("^(?=.*?[a-z])");

    var letters_capitals = new RegExp("^(?=.*?[A-Z])");

    var one_digit = new RegExp("^(?=.*?[0-9])");

    var special_char = new RegExp("^(?=.*?[#?!@$%^&*-])");

    if (!lower_case.test(password)) {
      setStrengthenPassword("Password should have at lease one lowercase");
    } else if (!letters_capitals.test(password)) {
      setStrengthenPassword("Password should have at least one uppercase");
    } else if (!one_digit.test(password)) {
      setStrengthenPassword("Password should contain at least one digit");
    } else if (!special_char.test(password)) {
      setStrengthenPassword("Password should contain one special character");
    } else if (password.length < 8) {
      setStrengthenPassword("Password should be more than 8 characters");
    } else {
      setStrengthenPassword("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== password_confirmation) {
      toast.error("Password do not match");
    }
    setLoading(true);
    axios.defaults.withCredentials = true;
    const baseURL = process.env.REACT_APP_BASEURL;
    const response = axios
      .get(`${baseURL}/sanctum/csrf-cookie`)
      .then((res) => {
        console.log(res);
        const baseURL = process.env.REACT_APP_BASEURL;
        return axios.post(
          `${baseURL}/api/v1/set-password`,
          {
            user_id,
            password,
            password_confirmation,
          },
          {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          }
        );
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setLoading(false);
          toast.success(res.data.message);
          setTimeout(() => {
            setredirect("/login");
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          console.log("skjfgskjakjg", error.response.data.errors);
          console.log("skjfgskjakjg", error.response.data.errors.password);
          setErrors(error.response.data.errors);
          toast.error(error.response.data.message);
          setLoading(false);
        }
      });
    return response;
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className="container">
      <ToastContainer />
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-md-6">
          <div className="my-5 card w-100 border-0 shadow">
            <div className="card-body">
              <div className="form-head">
                <h3 className="text-center text-primary">Reset Password</h3>
                <form className="form" onSubmit={handleSubmit}>
                  <label htmlFor="password">New Password</label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    required
                    className="form-control mb-3"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyUp={checkStrength}
                  />
                  <label htmlFor="password">Confirm Password</label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    required
                    className="form-control mb-3"
                    id="password_confirmation"
                    name="password_confirmation"
                    value={password_confirmation}
                    onChange={(e) => setpassword_confirmation(e.target.value)}
                  />
                  <p className="text-danger">{strengthenPassword}</p>
                  <p className="text-danger">{errors.password}</p>
                  <input
                    className="form-check-input ml-5"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    onClick={showPassword}
                  />
                  Show Password
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={loading}
                  >
                    Reset Password
                    {loading && <i className="fa fa-refresh fa-spin mx-2"></i>}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
