import axios from "axios";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { Redirect } from "react-router-dom";
import "../../user/CompletedOrders/CompletedOrders.css";
import Sidebar from "../Sidebar/Sidebar";

const SingleOrder = (props) => {
  console.log(props?.match?.params?.id);
  const [redirect, setRedirect] = useState("");
  const [orders, setOrders] = useState([]);
  console.log(props.match.params.id)
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/orders/${props?.match?.params?.id}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setOrders(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/user/login");
        }
      });
  }, [props?.match?.params?.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container my-5">
      <div className="row my">
        <div className="col-md-3">
          <Sidebar />
        </div>
        {orders ? (
          <div className="col-md-9">
            <div className="card border-0 shadow">
              <div className="card-body p-0">
                <p className="head">
                  <i className="bi bi-clock-history mx-2"></i> Order Hisotry
                </p>

                <div className="row">
                  <div className="row m-5">
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">title</p>
                      </div>
                      <div className="col-md-8">
                        <p>{orders.title}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">Category:</p>
                      </div>
                      <div className="col-md-8">
                        <p>{orders?.service?.name}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">Number of words:</p>
                      </div>
                      <div className="col-md-8">
                        <p>{orders.number_of_words}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">Price</p>
                      </div>
                      <div className="col-md-6">
                        {orders.currency}{" "}
                        <CurrencyFormat
                          value={orders.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">urgency:</p>
                      </div>
                      <div className="col-md-8">
                        <p>{orders.urgency}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">field</p>
                      </div>
                      <div className="col-md-8">
                        <p>{orders.english_version}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">bibliography</p>
                      </div>
                      <div className="col-md-6">
                        <p>{orders.bibliography}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">footnotes</p>
                      </div>
                      <div className="col-md-8">
                        <p>{orders.footnotes}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">style guide</p>
                      </div>
                      <div className="col-md-8">
                        <p>{orders.style_guide}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="title">Download</p>
                      </div>
                      <div className="col-md-8">
                        <p>
                          <a
                            className="badge rounded-pill bg-primary"
                            href={orders?.document}
                            target="_blank"
                            rel="noreferrer"
                          >
                            download
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-9 mt-5">
            <div className="card w-100">
              <div className="card-body p-0">
                <p className="head">
                  <i className="bi bi-clock-history mx-2"></i> Order Hisotry
                </p>
                <div className="container mt-4"></div>
                <div className="container">
                  <div className="row">
                    <div className="alert alert-danger p-2" role="alert">
                      <span>
                        {" "}
                        <i className="bi bi-exclamation-triangle"></i> Your
                        order history is currently empty. We look forward to
                        serving you.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleOrder;
