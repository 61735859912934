import React from "react";
import verify from "../images/verify.png";

const PasswordReset = () => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center vh-100">
        <div className="col-md-6">
          <div className="my-5 card w-100 border-0 shadow">
            <div className="card-body">
              <div className="form-head">
                <img src={verify} className="mx-auto d-block" alt="verify" />
                <h4 className="text-center mt-2">Reset password</h4>
                <p className="text-center">
                  We sent you an email. Go to your mailbox and click on the link
                  in the mail to reset your password.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
