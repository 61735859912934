import { EDITOR_REGISTER } from "../../actioinTypes";

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  password: '',
  password_confirmation: '',
}

const editorReducer = (state = initialState, action) => {
  switch(action.type) {
    case EDITOR_REGISTER:
      return {
        ...state,
        first_name:action.payload
      }
    default:
      return state
  }
}

export default editorReducer