import React from 'react'
import { Link } from 'react-router-dom'

const PricingOrder = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-12">
          <div className="card my-5 border-0 shadow">
            <div className="card-body">
              <div className="">
                <table className="table table-borderless m-3">
                <thead>
                  <tr>
                    <th scope="col" className='text-secondary'>Select</th>
                    <th scope="col" className='text-secondary'>No. of Words</th>
                    <th scope="col" className='text-secondary'>Duration</th>
                    <th scope="col" className='text-secondary'>Quote</th>
                    <th scope="col" className='text-secondary'>Delivery Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        </div></td>
                    <td>10, 000</td>
                    <td>24 hours</td>
                    <td>$ 40.00</td>
                    <td>23rd Oct 2021</td>
                  </tr>
                  <tr>
                    <td><div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        </div></td>
                    <td>10, 000</td>
                    <td>72 hours</td>
                    <td>$ 30.00</td>
                    <td>25th Oct 2021</td>
                  </tr>
                  <tr>
                    <td><div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        </div></td>
                    <td>10, 000</td>
                    <td>1 week</td>
                    <td>$ 10.00</td>
                    <td>29th Oct 2021</td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div className="my-5">
                <div className="d-flex justify-content-center align-items-center">
                <Link className='btn btn-primary shadow w-50' to='/payment'>Place Order</Link>
              </div>
              <div className='my-3 d-flex justify-content-center align-items-center'>
                <p>Your document will be kept safe and confidential using 256-bit SSL encryption.</p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingOrder
