import axios from "axios";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { Link, Redirect } from "react-router-dom";
import { EditorId } from "../../includes/Navbar";
import "../../user/CompletedOrders/CompletedOrders.css";
import Sidebar from "../Sidebar/Sidebar";

const AwaitingApproval = () => {
  const [redirect, setRedirect] = useState("");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/editors/${EditorId}/orders/uncompleted`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setOrders(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container-fluid my-5">
      <div className="row my">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "200px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : orders.length !== 0 ? (
            <div className="col-md-9">
              <div className="card border-0 shadow">
                <div className="card-body p-0">
                  <p className="head">
                    <i className="bi bi-clock-history mx-2"></i> Awaiting
                    Approval
                  </p>

                  <div className="row">
                    {orders?.map((order) => {
                      const {
                        id,
                        number_of_words,
                        title,
                        service,
                        price,
                        urgency,
                        english_version,
                        bibliography,
                        footnotes,
                        style_guide,
                        currency,
                        uuid,
                        deliverable,
                      } = order;
                      return (
                        <div className="row m-5  " key={id}>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">title</p>
                            </div>
                            <div className="col-md-8">
                              <p>{title}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">Category:</p>
                            </div>
                            <div className="col-md-8">
                              <p>{service.name}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">Number of words:</p>
                            </div>
                            <div className="col-md-8">
                              <p>{number_of_words}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">Price</p>
                            </div>
                            <div className="col-md-6">
                              {currency}{" "}
                              <CurrencyFormat
                                value={price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">urgency:</p>
                            </div>
                            <div className="col-md-8">
                              <p>{urgency}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">field</p>
                            </div>
                            <div className="col-md-8">
                              <p>{english_version}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">bibliography</p>
                            </div>
                            <div className="col-md-6">
                              <p>{bibliography}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">footnotes</p>
                            </div>
                            <div className="col-md-8">
                              <p>{footnotes}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <p className="title">style guide</p>
                            </div>
                            <div className="col-md-8">
                              <p>{style_guide}</p>
                            </div>
                          </div>
                          {deliverable === null ? (
                            <div>
                              <div className="row">
                                <div className="col-md-6">
                                  <Link
                                    className="btn btn-primary"
                                    to={"/editor/submit-order/" + uuid}
                                  >
                                    Submit Order
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="card w-100 border-0 shadow">
                <div className="card-body p-0">
                  <p className="head">
                    <i className="bi bi-clock-history mx-2"></i> Awaiting Orders
                  </p>
                  <div className="container mt-4"></div>
                  <div className="container">
                    <div className="row">
                      <div className="alert alert-danger p-2" role="alert">
                        <span>
                          {" "}
                          <i className="bi bi-exclamation-triangle"></i> You
                          have no order awaiting approval
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AwaitingApproval;
