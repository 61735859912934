
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Redirect } from "react-router-dom";

export const userId = localStorage.getItem("userId");

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(true);
  const [error, setError] = useState("");
  const [details, setDetails] = useState({});
  const [redirect, setRedirect] = useState("");

  const handleEvent = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.defaults.withCredentials = true;
    const baseURL = process.env.REACT_APP_BASEURL;
    const response = axios
      .put(
        `${baseURL}/api/v1/users/${userId}`,
        {
          first_name: details.first_name,
          last_name: details.last_name,
          email: details.email,
          phone: details.phone,
          bio: details.bio,
          academic_experience: [
            {
              institution: details.institution,
              course: details.course,
            },
          ],
          professional_experience: [
            {
              company: details.company,
              role: details.role,
            },
          ],
        },
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        toast.success(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          toast.error(error.response.data.message);
          setError(error.response.data.errors);
          setLoading(false);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setError(error.response.data.errors);
          setLoading(false);
        }
      });
    return response;
  };

  useEffect(() => {
    console.log(userId);
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/users/${userId}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setDetails(response.data.data);
        setLoadings(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/user/login");
        } else if (error.response.status === 401) {
          toast.error(error.response.message);
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-3">
          <div className="my-5 card border-0 shadow">
            <div className="card-body m-0 p-0">
              <div className="menu-items">
                <ul>
                  <li>
                    <Link to="/create-order" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-check2-square mx-2"></i> Completed
                          Orders
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/checkout" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-arrow-counterclockwise mx-2"></i>{" "}
                          In progress
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/order-history" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-clock-history mx-2"></i> Order
                          History
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/draft" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-journal-check mx-2"></i> Drafts
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="inactive">
                    <Link to="/settings" className="side-item">
                      <div className="menu-item">
                        <span className="text-primary">
                          <i className="bi bi-arrow-counterclockwise m-2"></i>{" "}
                          Account Settings
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className=" my-5 d-flex justify-content-center align-items-center">
                <button className="btn btn-primary shadow">New Order</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="container my-5">
            {!loadings ? (
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-6">
                  <div className="my-5 card border-0 shadow">
                    <div className="card-body">
                      <div className="form-head">
                        <h3 className="text-primary">user Profile</h3>
                        <p className="text-dark">Personal Information </p>
                      </div>
                      <form className="form" onSubmit={handleEvent}>
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="first_name">First name</label>
                            <input
                              type="text"
                              className="form-control mb-3"
                              placeholder="John"
                              id="first_name"
                              name="first_name"
                              value={details.first_name}
                              onChange={(e) =>
                                setDetails({
                                  ...details,
                                  first_name: e.target.value,
                                })
                              }
                            />
                            {error.first_name}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="last_name">Last name</label>
                            <input
                              type="text"
                              className="form-control mb-3"
                              placeholder="Doe"
                              id="last_name"
                              name="last_name"
                              value={details.last_name}
                              onChange={(e) =>
                                setDetails({
                                  ...details,
                                  last_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <label htmlFor="email">Email Address</label>
                        <input
                          type="email"
                          className="form-control mb-3"
                          placeholder="johndoe@yahoo.com"
                          id="email"
                          name="email"
                          value={details.email}
                          onChange={(e) =>
                            setDetails({ ...details, email: e.target.value })
                          }
                        />
                        <label htmlFor="phone">Phone number</label>
                        <input
                          type="tel"
                          className="form-control mb-3"
                          placeholder="+2347023483595"
                          id="phone"
                          name="phone"
                          value={details.phone}
                          onChange={(e) =>
                            setDetails({ ...details, phone: e.target.value })
                          }
                        />
                        {/* <label htmlFor="password">Password</label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    required
                    className="form-control mb-3"
                    placeholder="********"
                    id="password"
                    name="password"
                    value={details.password}
                    onChange={(e) =>
                      setDetails({ ...details, password: e.target.value })
                    }
                    onKeyUp={checkStrength}
                  />
                  <label htmlFor="password_confirmation">
                    Confirm Password
                  </label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    required
                    className="form-control mb-3"
                    placeholder="********"
                    id="password_confirmation"
                    name="confrim_password"
                    value={details.password_confirmation}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        password_confirmation: e.target.value,
                      })
                    }
                  />
                  <p className="text-danger">{strengthenPassword}</p>
                  <p className="text-danger">{error.password}</p>
                  <input
                    className="form-check-input ml-5"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    onClick={showPassword}
                  />
                  <span className="mx-3">Show Password</span> */}
                        <button
                          type="submit"
                          className="btn btn-primary shadow"
                          disabled={loading}
                        >
                          Update Profile
                          {loading && (
                            <i className="fa fa-refresh fa-spin mx-2"></i>
                          )}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                <ToastContainer autoClose={3000} />
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "300px" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings
