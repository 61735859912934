import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [redirect, setredirect] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
      setLoading(true);
      axios.defaults.withCredentials = true;
      const baseURL = process.env.REACT_APP_BASEURL;
      const response = axios
        .get(`${baseURL}/sanctum/csrf-cookie`)
        .then((res) => {
          console.log(res)
          const baseURL = process.env.REACT_APP_BASEURL;
          return axios.post(
            `${baseURL}/api/v1/forgot-password`,
            {
              email,
            },
            {
              xsrfHeaderName: "X-XSRF-TOKEN",
              withCredentials: true,
            }
          );
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            setLoading(false);
            toast.success(res.data.message);
            setTimeout(() => {
              setredirect("/password-reset");
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            toast.error(error.response.data.message);
            setLoading(false);
          }
        });
      return response;  
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center">
        <ToastContainer />
        <div className="col-md-6">
          <div className="my-5 card w-100 border-0 shadow">
            <div className="card-body">
              <div className="form-head">
                <h3 className="text-center text-primary">Forgot Password</h3>
                <p className="text-center text-dark">
                  Enter your email to reset your password
                </p>
                <form className="form" onSubmit={handleSubmit}>
                  <label htmlFor="name">Email</label>
                  <input
                    type="email"
                    required
                    className="form-control mb-3"
                    placeholder="johndoe@yahoo.com"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={loading}
                  >
                    Reset Password
                    {loading && <i className="fa fa-refresh fa-spin mx-2"></i>}
                  </button>
                </form>
                <p className="text-center">
                  <Link to="/login">Back to Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword
