import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import Footer from "../Footer/Footer";
import "./PlaceOrder.css";

const PlaceOrder = (props) => {
  const [pricing, setPricing] = useState([]);
  const [plan, setPlan] = useState("");
  const [loadings, setLoadings] = useState(true);
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState("");
  const [count, setCount] = useState("");
  const [currency, setCurrrency] = useState("");
  const [order, setOrder] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [disbaleCount, setDisableCount] = useState(false);
  const [disbaleUpload, setDisableUpload] = useState(false);

  const service_id = props?.match?.params?.id;
  console.log("Service Id >>>>>>>>>", service_id);
  localStorage.setItem("service_id", service_id);

  const handleCount = (e) => {
    console.log(plan);
    console.log(selectedFile)
    console.log("count the number of words");
    console.log(count);
    const data = new FormData();
    if (selectedFile) {
      data.append("upload_preset", "write-right");
      data.append("file", selectedFile);
      data.append("plan_id", plan);
      data.append("currency", currency);
    } else {
      data.append("upload_preset", "write-right");
      data.append("number_of_words", count);
      data.append("plan_id", plan);
      data.append("currency", currency);
    }
    setLoading(true);
    console.log(data);
    const baseURL = process.env.REACT_APP_BASEURL;

    axios
      .post(`${baseURL}/api/v1/pricing`, data, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        console.log(response.data);
        console.log(response.data.data.price);
        setPrices(response.data.data);
        setLoading(false);
        setOrder(true);
        localStorage.setItem("document", selectedFile)
      });
  };

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/plans`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data['data']);
        setPricing(response.data['data']);
        setLoadings(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <div className="dis-bg">
        <div className="container">
          <ToastContainer />
          <div className="row">
            <div className="col-md-6">
              <h2 className="dis-head">
                <i className="bi bi-globe"></i>
                English Dissertation, Thesis, or Proposal Editing
              </h2>
              <h5 className="dis-point">Fast, Affordable, Professional</h5>
              <p className="dis-desc">
                Writing a major academic work can be difficult enough, but it is
                even more so when working in a foreign language. Let our
                professional editors help you express yourself clearly in
                English.
              </p>
              <button type="button" className="btn btn-outline-warning">
                <i className="bi bi-cart mx-2"></i>
                Order Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <p>
                Having an English expert at your side during your graduate
                studies can be critical to your success. Your supervisor won't
                have the time to assist you with matters of language; indeed,
                most advisors barely have enough time to help you with research
                questions.
              </p>
              <p>
                Let us help. We'll revise your work for style, organization,
                word choice, concision, and clarity. We'll show you where you
                need to provide more support for your arguments and give you
                constructive criticism that will help you improve your English.
                We will correct spelling, grammar, and typing errors, allowing
                you to focus on creating great content.
              </p>
              <p>
                We have experienced editors in every academic field. They have
                worked on many academic projects. We ensure that your work is
                assigned to the editor who will give you the best value and help
                ensure the success of your work.
              </p>
              <h3 className="my-5">What will you receive?</h3>
              <ul>
                <li>
                  An edited document in which the spelling errors, grammar
                  errors, and typos have been corrected
                </li>
                <li>
                  Changes and suggestions to help improve clarity, flow,
                  structure, and readability
                </li>
                <li>
                  Formatting of citations and references to a style guide of
                  your choice, if desired
                </li>
                <li>
                  Tracked revisions so that you can see and review all the
                  changes that have been made
                </li>
                <li>In-depth critical commentary on your paper </li>
                <li>
                  A short summary of the work that has been done and any major
                  concerns
                </li>
              </ul>
              <p>
                Rest assured, whether you're working on commercially sensitive
                data or conducting a groundbreaking scholarly analysis, your
                manuscript will be kept confidential. Uploads and downloads to
                our server are securely encrypted, and we have very strict
                privacy policies. We handle most major file types, and we are
                excited to work with you.
              </p>
              <p>
                Editing help is just a couple of clicks away. Get an instant
                quote below, and order today!
              </p>
            </div>
          </div>
          <div className="row">
            <h3 className="my-5">
              <i className="bi bi-calculator mx-2"></i>Get an Instant Quote and
              Place Your Order
            </h3>
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="text-center">
                    {!disbaleUpload && (
                      <div className="row center mb-2">
                        <div className="col-md-6">
                          <p className="float-end">
                            <strong>
                              Please upload your document: <br />
                            </strong>
                            {/* <small>
                            We will calculate your word count for you
                          </small> */}
                          </p>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="file"
                            className="form-control text-primary btn-light"
                            name="file"
                            onChange={(e) => {
                              setSelectedFile(e.target.files[0]);
                              setCount("");
                              setDisableCount(true);
                              if (plan || currency) {
                                handleCount(e);
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {!disbaleCount ? (
                      <div className="row center mb-2">
                        <div className="col-md-4">
                          <p className="float-end">
                            <strong>Or, enter the word count:</strong>
                          </p>
                        </div>
                        <div className="col-md-2">
                          <input
                            type="text"
                            className="form-control"
                            value={count}
                            onChange={(e) => setCount(e.target.value)}
                            onKeyUp={(e) => {
                              setCount(e.target.value);
                              setDisableUpload(true);
                              if (currency !== "" && plan !== "") {
                                handleCount(e);
                              }
                            }}
                            // onKeyUp={handleCount}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="row center mb-3">
                      <div className="col-md-4">
                        <p className="float-end">
                          <strong>Show your quote in this currency:</strong>
                        </p>
                      </div>
                      <div className="col-md-2">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={currency}
                          onChange={(e) => {
                            setCurrrency(e.target.value);
                            setCurrrency(e.target.value);
                            if (selectedFile !== "" && plan !== "") {
                              handleCount(e);
                            } else if (plan !== "" && count !== "") {
                              handleCount(e);
                            }
                          }}
                          // onClick={(e) => {
                          //   setCurrrency(e.target.value);
                          //   if (selectedFile !== "" && plan !== "") {
                          //     handleCount(e);
                          //   } else if (plan !== "" && count !== "") {
                          //     handleCount(e);
                          //   }
                          // }}
                        >
                          <option>--Select--</option>
                          <option value="NGN">Naira (NGN)</option>
                          <option value="USD">Dollar (USD)</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className="row">
                      {pricing.map((price) => (
                        <div key={price.id}>
                          <div
                            className="col urgency"
                            value={plan}
                            onClick={(e) => {
                              setPlan(price.uuid);
                              console.log('plan id',plan)
                              console.log(price.uuid)
                              if (count !== "" && currency !== "") {
                                handleCount(e);
                              } else if (
                                selectedFile !== "" &&
                                currency !== ""
                              ) {
                                handleCount(e);
                              }
                            }}
                          >
                            {price.urgency}
                          </div>
                        </div>
                      ))}
                    </div> */}

                    {/* <div className="form-check">
                      {pricing.map((price) => (
                        <div key={price.id}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name='urgency'
                            value={price.uuid}
                            id={price.id}
                            onChange={(e) => {
                              setPlan(e.target.value);
                              if (count !== "" && currency !== "") {
                                handleCount(e);
                              } else if (
                                selectedFile !== "" &&
                                currency !== ""
                              ) {
                                handleCount(e);
                              }
                            }}
                          />
                          <label className="form-check-label mb-3">
                            {price.urgency}
                          </label>
                        </div>
                      ))}
                    </div> */}
                  </div>
                  {loadings ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    <div className="row center mb-5">
                      <div className="col-md-4 mb-4">
                        <p>
                          <strong className="float-end">Select Urgency:</strong>
                        </p>
                      </div>
                      <div className="col-md-2">
                        <select
                          className="form-select mb-3"
                          aria-label="Default select example"
                          type="text"
                          required
                          placeholder="John"
                          id="currency"
                          name="currency"
                          value={plan}
                          onChange={(e) => {
                            setPlan(e.target.value);
                          }}
                          onClick={(e) => {
                            if (count !== "" && currency !== "") {
                              handleCount(e);
                            } else if (selectedFile !== "" && currency !== "") {
                              handleCount(e);
                            }
                          }}
                        >
                          <option defaultValue="1">--Select--</option>
                          {pricing?.map((price) => {
                            const { id, uuid, urgency } = price;
                            return (
                              <option key={id} value={uuid}>
                                {urgency}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <hr className="mt-5" />
                    </div>
                  )}

                  {loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h5 className="">
                      {/* <CurrencyFormat
                        value={prices?.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currency === "USD" ? "$" : "₦"}
                      /> */}
                      {prices?.price}
                    </h5>
                  )}

                  <div className="float-end">
                    {order && (
                      <a
                        href={`/create-order/${service_id}#order`}
                        className="btn btn-primary w-100 shadow"
                        disabled={order}
                      >
                        Place Order
                      </a>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PlaceOrder;
