import axios from 'axios'
import React from 'react'
import { ToastContainer, toast } from "react-toastify";
import verify from '../images/verify.png'

const Verify = () => {
  const resendMail = (e) => {
    e.preventDefault()
    console.log('clicked')
    axios.defaults.withCredentials = true;
    const baseURL = process.env.REACT_APP_BASEURL;
    const response = axios
      .get(`${baseURL}/sanctum/csrf-cookie`)
      .then((response) => {
        console.log(response)
        const baseURL = process.env.REACT_APP_BASEURL;
        return axios.post(
          `${baseURL}/email/resend`,
          {
          },
          {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          }
        );
      })
      .then((res) => {
        console.log(res)
        })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      });
    return response;
  }
  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center vh-100">
        <ToastContainer />
        <div className="col-md-6">
          <div className="my-5 card w-100 border-0 shadow">
            <div className="card-body">
              <div className="form-head">
                <img src={verify} className="mx-auto d-block" alt="verify" />
                <h4 className="text-center mt-2">Verify Email</h4>
                <p className="text-center">
                  We sent you an email. Go to your mailbox and click on the link
                  in the mail to verify your account.
                </p>
                <p className="text-center mt-5">
                  Didn't get a mail?{" "}
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={resendMail}
                  >
                    Resend
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify
