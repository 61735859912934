import React from 'react'
import user from '../images/user.png'
import editor from "../images/editor.png";
import { Link } from 'react-router-dom';

const SignUp = () => {

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center mt-5">
        <div className="col-md-6">
          <h3 className="text-center mt-4">User</h3>
          <img className="img-fluid mb-5" src={user} alt="" />
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/user/register" className="btn btn-primary">
              Register as a User
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <h3 className="text-center mt-4">Editor</h3>
          <img className="img-fluid" src={editor} alt="" />
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/editor/register" className="btn btn-primary">
              Register as an Editor
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp
