import React from 'react';

const EditorSubmitOne = () => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center vh-100">
        <div className="col-md-8">
          <div className="card border-0">
            <div className="card-body">
              <p className="text-dark">
                Thank you for submitting and completing the continuous
                assessment! You will be notified if you qualify for the next
                round
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorSubmitOne;
