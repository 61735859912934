import React from "react";
import { Link } from "react-router-dom";

const EditorDashboard = () => {

  return (
    <div className="container mb-3">
      <div className="row">
        <div className="col-md-3">
          <div className="my-5 card border-0 shadow">
            <div className="card-body m-0 p-0">
              <div className="menu-items">
                <ul>
                  <li className="inactive">
                    <Link to="/editor/feeds" className="side-item">
                      <div className="menu-item">
                        <span className="text-primary">
                          <i className="bi bi-arrow-counterclockwise mx-2"></i>{" "}
                          Feeds
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/editor/completed-orders" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-check2-square mx-2"></i> Completed
                          Orders
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/editor/awaiting-approval" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-arrow-counterclockwise mx-2"></i>{" "}
                          Awaiting Approval
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/editor/order-history" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-clock-history mx-2"></i> Order
                          History
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/editor/transactions" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-arrow-left-right mx-2"></i>{" "}
                          Transactions
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/editor-profile" className="side-item">
                      <div className="menu-item">
                        <span>
                          <i className="bi bi-arrow-counterclockwise m-2"></i>{" "}
                          Account Settings
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className=" my-5 d-flex justify-content-center align-items-center">
                <Link to="/editor/feeds" className="btn btn-primary shadow">
                  New Order
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          {/* <div className="my-5 card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-borderless m-3">
                  <thead>
                    <tr>
                      <th scope="col" className="text-secondary">
                        JOB N.
                      </th>
                      <th scope="col" className="text-secondary">
                        FILE NAME
                      </th>
                      <th scope="col" className="text-secondary">
                        DUE DATE
                      </th>
                      <th scope="col" className="text-secondary">
                        QUOTE
                      </th>
                      <th scope="col" className="text-secondary">
                        PAYMENT STATUS
                      </th>
                      <th scope="col" className="text-secondary">
                        JOB STATUS
                      </th>
                      <th scope="col" className="text-secondary">
                        Rating
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Thesis.docx</td>
                      <td>22 Oct 2021</td>
                      <td>$ 120</td>
                      <td>Payment made</td>
                      <td>Pending</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Thesis.docx</td>
                      <td>22 Oct 2021</td>
                      <td>$ 120</td>
                      <td>Payment made</td>
                      <td>Pending</td>
                      <td>--</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
          <div className="row my-5">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">this is it</div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">this is it</div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">this is it</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorDashboard;
