import axios from "axios";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { EditorId } from "../../includes/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import "./Transactions.css";

const TransactionDetails = (props) => {
  const [redirect, setRedirect] = useState("");
  const [transactions, setTransactions] = useState({});
  const [loading, setLoading] = useState(true);
  console.log(props)
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(
        `${baseURL}/api/v1/editors/${EditorId}/transactions/${props?.match?.params?.id}`,
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data.data);
        setTransactions(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, [props?.match?.params?.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container-fluid my-5">
      <div className="row my">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "200px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : transactions.length !== 0 ? (
            <div className="col-md-12">
              <div className="card border-0 shadow">
                <div className="card-body">
                  <h3 className="p-4">Transaction Details</h3>
                  <div className="row mb-4">
                    <div className="col-md-3">
                      <strong>Reference</strong>
                    </div>
                    <div className="col-md-9">
                      <span>{transactions.reference}</span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-3">
                      <strong>Description</strong>
                    </div>
                    <div className="col-md-9">
                      <span>{transactions.description}</span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-3">
                      <strong>Status</strong>
                    </div>
                    <div className="col-md-9">
                      <span className="text-success">
                        {transactions.status}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-3">
                      <strong>Type</strong>
                    </div>
                    <div className="col-md-9">
                      <span>{transactions.type}</span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-3">
                      <strong>Amount</strong>
                    </div>
                    <div className="col-md-9">
                      <span>{transactions.amount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="card w-100 border-0 shadow">
                <div className="card-body p-0">
                  <p className="head">
                    <i className="bi bi-clock-history mx-2"></i> Transactions
                  </p>
                  <div className="container mt-4"></div>
                  <div className="container">
                    <div className="row">
                      <div className="alert alert-danger p-2" role="alert">
                        <span>
                          {" "}
                          <i className="bi bi-exclamation-triangle"></i> You
                          currrently have no transactions
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
