import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import Sidebar from "../Sidebar/Sidebar";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";



const AdminDashboard = () => {
  const [stats, setStats] = useState();
  const [loading, setLoading] = useState();
  const [redirect, setRedirect] = useState();


  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/stats`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setStats(response.data.data);
        setLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="dashboard">
      <div className="container-fluid">
        <div className="row bg-white">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="my-5"></div>
            <div className="row m-4">
              <div className="col-md-3">
                <Link to="/admin/users">
                  <div
                    className="card border-0 shadow mb-5 bg-primary"
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="card-body">
                      <div className="card-details">
                        <i className="bi bi-people m-3 icon"></i>
                        <p className="text-light my-4">Users</p>
                        <p className="stat">{stats?.userCount}</p>
                        <p className="new">
                          + {stats?.weeklyUserCount} this week
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to="/admin/editors">
                  <div
                    className="card border-0 shadow mb-5"
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="card-body">
                      <div className="card-details">
                        <i className="bi bi-file-earmark-bar-graph m-3 icon text-primary"></i>
                        <p className="my-4">Editors</p>
                        <p className="stat text-dark">{stats?.editorCount}</p>
                        <p className="new">
                          + {stats?.weeklyEditorCount} this week
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-6">
                <h6 className="db-head">Payments</h6>
                <div
                  className="card border-0 shadow"
                  style={{ borderRadius: "15px" }}
                >
                  <div className="card-body">
                    <div className="row mb-1">
                      <div className="col-md-2">
                        <img
                          className="img-fluid db-img"
                          width="100"
                          height="100"
                          src="https://cdn.pixabay.com/photo/2022/04/06/11/30/girl-7115394__340.jpg"
                          alt="editors"
                        />
                      </div>
                      <div className="col-md-10">
                        <p className="rank mx-4">
                          Total Payments <br />{" "}
                          <span className="top">₦ {stats?.totalPayments}</span>
                        </p>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-md-2">
                        <img
                          className="img-fluid db-img"
                          width="100"
                          height="100"
                          src="https://cdn.pixabay.com/photo/2022/04/06/11/30/girl-7115394__340.jpg"
                          alt="editors"
                        />
                      </div>
                      <div className="col-md-10">
                        <p className="rank mx-4">
                          Logo design for bakery <br />{" "}
                          <span className="top">1 day remaining</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <p className="projects">
                        <Link to='/admin/payments'>see all payments</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-4">
              <div className="col-md-4">
                <Link to="/admin/payments">
                  <div
                    className="card border-0 shadow mb-5"
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="card-body">
                      <div className="card-details">
                        <i className="bi bi-file-earmark-bar-graph m-3 icon text-primary"></i>
                        <p className="my-4">Total Payments</p>
                        <p className="stat text-dark">
                          ₦ {stats?.totalPayments}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/admin/orders">
                  <div
                    className="card border-0 shadow mb-5"
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="card-body">
                      <div className="card-details">
                        <i className="bi bi-file-earmark-bar-graph m-3 icon text-primary"></i>
                        <p className="my-4">Pending Order</p>
                        <p className="stat text-dark">
                          {stats?.pendingOrderCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="/admin/interviews">
                  <div
                    className="card border-0 shadow mb-5"
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="card-body">
                      <div className="card-details">
                        <i className="bi bi-file-earmark-bar-graph m-3 icon text-primary"></i>
                        <p className="my-4">Upcoming Interviews</p>
                        <p className="stat text-dark">
                          {stats?.upcomingInterviewCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="row m-4">
              <div className="col-md-6">
                <h6 className="db-head">Recent Invoices</h6>
                <div
                  className="card border-0 shadow mb-3"
                  style={{ borderRadius: "15px" }}
                >
                  <div className="card-body">
                    <div className="row my-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              className="img-fluid db-img2"
                              width="100"
                              height="100"
                              src="https://cdn.pixabay.com/photo/2022/04/06/11/30/girl-7115394__340.jpg"
                              alt="editors"
                            />
                          </div>
                          <div className="col-md-10">
                            <p className="rank mx-4">
                              Alex Williams <br />{" "}
                              <span className="top">ABC holdings</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="paid">
                          <span className="bg-paid">Paid</span>{" "}
                          <span className="mx-3">$ 1,200.87</span>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              className="img-fluid db-img2"
                              width="100"
                              height="100"
                              src="https://cdn.pixabay.com/photo/2022/04/06/11/30/girl-7115394__340.jpg"
                              alt="editors"
                            />
                          </div>
                          <div className="col-md-10">
                            <p className="rank mx-4">
                              Alex Williams <br />{" "}
                              <span className="top">ABC holdings</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="paid">
                          <span className="bg-paid">Paid</span>{" "}
                          <span className="mx-3">$ 1,200.87</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card border-0 shadow"
                  style={{ borderRadius: "15px", backgroundColor: "#220942" }}
                >
                  <div className="card-body">
                    <div className="row my-2">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-2">
                            <i className="bi bi-slack mx-4 icon"></i>
                          </div>
                          <div className="col-md-10">
                            <p className="rank text-white mx-4">
                              Engage with clients <br />{" "}
                              <span className="top text-light">
                                Join slack channel
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="join">
                          <p className="join-now">Join now</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h6 className="db-head">Recommended Projects</h6>
                <div
                  className="card border-0 shadow"
                  style={{ borderRadius: "15px" }}
                >
                  <div className="card-body">
                    <div className="row my-2">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              className="img-fluid db-img2"
                              width="100"
                              height="100"
                              src="https://cdn.pixabay.com/photo/2022/04/06/11/30/girl-7115394__340.jpg"
                              alt="editors"
                            />
                          </div>
                          <div className="col-md-10">
                            <p className="rank mx-4">
                              Thomas Martin <br />{" "}
                              <span className="top">updated 10m ago</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="paid">
                          <span className="bg-design">Design</span>{" "}
                        </div>
                      </div>
                      <p className="text-dark">
                        Need a designer to form branding essentials for my
                        business
                      </p>
                      <p>
                        Looking for a talented brand designer to create all the
                        branding materials for my new startup
                      </p>
                      {/* <div className="row bg-full">
                        <div className="col-md-6">
                          <p className="month">$8700/month</p>
                        </div>
                        <div className="col-md-6 join">
                          <p className="full-time">full time</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
