import axios from "axios";
import React, { useState, useEffect } from "react";
import Footer from "./Footer/Footer";
import "./Pricing.css";

const Pricing = () => {
  const [plan, setPlan] = useState("");
  const [pricing, setPricing] = useState([]);
  const [count, setCount] = useState("");
  // const fileInput = useRef(null)
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState("");
  const [currency, setCurrrency] = useState("");
  const [selectedFile, setSelectedFile] = useState('');
  const [disbaleCount, setDisableCount] = useState(false);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/plans`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setPricing(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCount = (e) => {
    console.log(plan);
    console.log(selectedFile);
    console.log("count the number of words");
    console.log(count);
    const data = new FormData();
    if (selectedFile) {
      data.append("upload_preset", "write-right");
      data.append("file", selectedFile);
      data.append("plan_id", plan);
      data.append("currency", currency);
    } else {
      data.append("upload_preset", "write-right");
      data.append("number_of_words", count);
      data.append("plan_id", plan);
      data.append("currency", currency);
    }
    setLoading(true);
    console.log(data);
    const baseURL = process.env.REACT_APP_BASEURL;

    axios
      .post(`${baseURL}/api/v1/pricing`, data, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        console.log(response.data);
        console.log(response.data.data.price);
        setPrices(response.data.data);
        setLoading(false);
        setDisableCount(false)
        // localStorage.setItem("document", selectedFile);
      });
  };

  return (
    <>
      <div className="dis-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="dis-head">
                <i className="bi bi-globe"></i>
                Check our Pricing
              </h2>
              <h5 className="dis-point">Fast, Affordable, Professional</h5>
              <p className="dis-desc">
                Writing a major academic work can be difficult enough, but it is
                even more so when working in a foreign language. Let our
                professional editors help you express yourself clearly in
                English.
              </p>
              <a
                href="#price"
                type="button"
                className="btn btn-outline-warning"
              >
                <i className="bi bi-cart mx-2"></i>
                Check Price
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10">
            <h3 className="my-5">
              Get the English Editing You Need for Your Thesis or Dissertation
            </h3>
            <p>
              Having an English expert at your side during your graduate studies
              can be critical to your success. Your supervisor won't have the
              time to assist you with matters of language; indeed, most advisors
              barely have enough time to help you with research questions.
            </p>
            <p>
              Let us help. We'll revise your work for style, organization, word
              choice, concision, and clarity. We'll show you where you need to
              provide more support for your arguments and give you constructive
              criticism that will help you improve your English. We will correct
              spelling, grammar, and typing errors, allowing you to focus on
              creating great content.
            </p>
            <p>
              We have experienced editors in every academic field. They have
              worked on many academic projects. We ensure that your work is
              assigned to the editor who will give you the best value and help
              ensure the success of your work.
            </p>
            <h3 className="my-5">What will you receive?</h3>
            <ul>
              <li>
                An edited document in which the spelling errors, grammar errors,
                and typos have been corrected
              </li>
              <li>
                Changes and suggestions to help improve clarity, flow,
                structure, and readability
              </li>
              <li>
                Formatting of citations and references to a style guide of your
                choice, if desired
              </li>
              <li>
                Tracked revisions so that you can see and review all the changes
                that have been made
              </li>
              <li>In-depth critical commentary on your paper </li>
              <li>
                A short summary of the work that has been done and any major
                concerns
              </li>
            </ul>
            <h3 className="my-5">
              What types of documents can you use this service for?
            </h3>
            <ul>
              <li>Thesis</li>
              <li>Dissertation</li>
              <li>Thesis and dissertation proposals</li>
            </ul>
            <p>
              Rest assured, whether you're working on commercially sensitive
              data or conducting a groundbreaking scholarly analysis, your
              manuscript will be kept confidential. Uploads and downloads to our
              server are securely encrypted, and we have very strict privacy
              policies. We handle most major file types, and we are excited to
              work with you.
            </p>
            <p>
              Editing help is just a couple of clicks away. Get an instant quote
              below, and order today!
            </p>
          </div>
        </div>
      </div>
      <div className="pricing pt-5" id="price">
        <div className="container">
          <div className="row center m-5">
            <div className="col-md-8">
              <div className="card mb-5 border-0 shadow">
                <div className="card-body">
                  <p className="text-center">
                    To add files to your order, click the{" "}
                    <span>'Choose File'</span> button below.
                  </p>
                  <div className="row center">
                    <div className="col-md-5">
                      <input
                        required
                        type="file"
                        className="form-control text-primary btn-light"
                        name="file"
                        onChange={(e) => {
                          setSelectedFile(e.target.files[0]);
                          console.log(e.target.files[0])
                          setDisableCount(true);
                          setCount("");
                          if(currency !=='' && plan !== '') {
                            handleCount(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {!disbaleCount ? (
                    <div className="row center mb-2">
                      <div className="col-md-4">
                        <p>Or, enter the word count:</p>
                      </div>
                      <div className="col-md-2">
                        <input
                          type="text"
                          className="form-control"
                          value={count}
                          onChange={(e) => setCount(e.target.value)}
                          onKeyUp={(e) => {
                            setCount(e.target.value);
                            setSelectedFile("");
                            if (currency !=='' && plan !==''&& count !=='') {
                              handleCount(e);
                            }
                          }}
                          // onKeyUp={handleCount}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="m-3 center">
                    <label htmlFor="" className="form-label mx-3">
                      <span className="text-danger">*</span> Select Currency
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="currency"
                        name="currency"
                        value={currency}
                        onChange={(e) => {
                          setCurrrency(e.target.value);
                        }}
                        onClick={(e) => {
                          if(plan !=='' && currency !=='') {
                            handleCount(e);
                          }
                        }}
                      >
                        <option defaultValue="1">--Select--</option>
                        <option value="NGN">Naira (NGN)</option>
                        <option value="USD">Dollar (USD)</option>
                      </select>
                    </div>
                  </div>
                  <div className="m-3 center">
                    <label htmlFor="" className="form-label mx-3">
                      <span className="text-danger">*</span> Select Urgency
                    </label>
                    <div className="col-md-5">
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        type="text"
                        required
                        placeholder="John"
                        id="currency"
                        name="currency"
                        value={plan}
                        onChange={(e) => {
                          setPlan(e.target.value);
                        }}
                        onClick={(e) => {
                          if (currency !== '' && count !=='') {
                            handleCount(e);
                          } else if(selectedFile !== '' && currency !=='') {
                            handleCount(e);
                          }
                        }}
                      >
                        <option defaultValue="1">--Select--</option>
                        {pricing?.map((price) => {
                          const { id, uuid, urgency } = price;
                          return (
                            <option key={id} value={uuid}>
                              {urgency}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <hr />
                  {loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h5 className="">
                      {/* <CurrencyFormat
                        value={prices?.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currency === "USD" ? "$" : "₦"}
                      /> */}
                      {prices?.price}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Pricing;
