import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";
import "../Users/Users.css";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/admin/orders`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setOrders(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/admin/login");
          }, 3000);
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row bg-white">
          <div className="col-md-2 left-side">
            <Sidebar />
          </div>
          <div className="col-md-10 right-side">
            <div className="top"></div>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "200px" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <div className="card border-0">
                  <div className="card-body">
                    <div className="row feture-tabs" data-aos="fade-up">
                      <div className="col-lg-12">
                        <ul className="nav nav-pills mb-3">
                          <li>
                            <a
                              className="nav-link active"
                              data-bs-toggle="pill"
                              href="#tab1"
                            >
                              All Orders
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab2"
                            >
                              Pending Orders
                            </a>
                          </li>
                        </ul>
                        <div className="float-end"></div>
                        <div className="tab-content">
                          <div className="tab-pane fade show active" id="tab1">
                            <h4>All Orders</h4>
                            <table className="table table-borderless table-striped table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">TITLE</th>
                                  <th scope="col">CATEGORY</th>
                                  <th scope="col">DESCRIPTION</th>
                                  <th scope="col">URGENCY</th>
                                  <th scope="col">WORDS</th>
                                  <th scope="col">PRICE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order, index) => {
                                  const {
                                    id,
                                    title,
                                    service,
                                    urgency,
                                    uuid,
                                    currency,
                                    price,
                                    number_of_words,
                                  } = order;
                                  return (
                                    <tr
                                      key={id}
                                      onClick={() =>
                                        setRedirect(
                                          "/admin/order-details/" + uuid
                                        )
                                      }
                                    >
                                      <td>{title}</td>
                                      <td>{service?.name}</td>
                                      <td>{service?.description}</td>
                                      <td>{urgency}</td>
                                      <td>{number_of_words}</td>
                                      <td>
                                        {currency} {price}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <div className="tab-pane fade show" id="tab2">
                            <h4>Pending Orders</h4>
                            <table className="table table-borderless table-striped table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">TITLE</th>
                                  <th scope="col">CATEGORY</th>
                                  <th scope="col">DESCRIPTION</th>
                                  <th scope="col">URGENCY</th>
                                  <th scope="col">WORDS</th>
                                  <th scope="col">PRICE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order, index) => {
                                  const {
                                    id,
                                    title,
                                    service,
                                    urgency,
                                    uuid,
                                    currency,
                                    price,
                                    number_of_words,
                                  } = order;
                                  return (
                                    order.editor === null && (
                                      <tr
                                        key={id}
                                        onClick={() =>
                                          setRedirect(
                                            "/admin/order-details/" + uuid
                                          )
                                        }
                                      >
                                        <th scope="row">{index + 1}</th>
                                        <td>{title}</td>
                                        <td>{service?.name}</td>
                                        <td>{service?.description}</td>
                                        <td>{urgency}</td>
                                        <td>{number_of_words}</td>
                                        <td>
                                          {currency} {price}
                                        </td>
                                      </tr>
                                    )
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
