import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { EditorId } from "../../includes/Navbar";

const secondLevel = localStorage.getItem("secondLevelAccess");
const Sidebar = () => {
  const [redirect, setRedirect] = useState("");
  const [details, setDetails] = useState("");

  useEffect(() => {
    console.log(EditorId);
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/editors/${EditorId}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        localStorage.setItem('second_level',response.data.data.second_level_access)

        setDetails(response.data['data']);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, []);

  const logout = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to logout?")) {
      axios.defaults.withCredentials = true;
      const baseURL = process.env.REACT_APP_BASEURL;
      const response = axios
        .get(`${baseURL}/sanctum/csrf-cookie`)
        .then((res) => {
          console.log(res);
          const baseURL = process.env.REACT_APP_BASEURL;
          return axios.post(`${baseURL}/logout`, {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          });
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            toast.success(res.data.message);
            localStorage.clear("EditorId");
            setTimeout(() => {
              setRedirect("/");
              window.location.reload(1);
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            toast.error(error.response.data.message);
          }
        });
      return response;
    }
    return false;
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="mb-4 card border-0 shadow sticky-top">
      <div className="card-body">
        <ToastContainer autoClose={3000} />
        <div className="menu-items">
          <ul>
            <li>
              <Link to="/editor/feeds" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-arrow-counterclockwise mx-2"></i> Feeds{" "}
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/editor/completed-orders" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-check2-square mx-2"></i> Completed{" "}
                    Orders {details.second_level_access}
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/editor/awaiting-approval" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-arrow-counterclockwise mx-2"></i>{" "}
                    Awaiting Approval
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/editor/order-history" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-clock-history mx-2"></i> Order History
                  </span>
                </div>
              </Link>
            </li>
            {secondLevel === 'true' ? (
              <li>
                <Link to="/editor/deliverables" className="side-item">
                  <div className="menu-item">
                    <span>
                      <i className="bi bi-file-check mx-2"></i> Deliverables
                    </span>
                  </div>
                </Link>
              </li>
            ) : (
              ""
            )}
            <li>
              <Link to="/editor/transactions" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-arrow-left-right mx-2"></i> Transactions
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/editor/payments" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-wallet2 mx-2"></i> Payments
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/editor-profile" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-arrow-counterclockwise m-2"></i> Account
                    Settings
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <div className="menu-item mt-3">
                <p className="text-danger" onClick={logout}>
                  <i className="bi bi-box-arrow-left mx-1"></i> Logout
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className=" my-5 d-flex justify-content-center align-items-center">
          <Link to="/editor/feeds" className="btn btn-primary shadow">
            New Order
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
