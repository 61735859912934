import axios from "axios";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { EditorId } from "../../includes/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const Withdrawal = (props) => {
  const [redirect, setRedirect] = useState("");
  const [transactions, setTransactions] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("button clicked");
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .post(`${baseURL}/api/v1/editors/${EditorId}/request-withdrawal`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        console.log(response.data);
        setTransactions(response.data.data);
        toast.success(response.data.message);
        setLoading(false);
        setTimeout(() => {
          setRedirect("/editor/awaiting-approval");
        });
      }, 3000)
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          setTransactions(error.response.data.data);
          toast.error(error.response.data.message);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container-fluid my-5">
      <div className="row my">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9 mt-5">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "200px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : transactions.length !== 0 ? (
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="p-4">Withdrawal</h3>
                  <p>Make Withdrawal</p>
                  <button onClick={handleSubmit} className="btn btn-primary">
                    Withdraw
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="card w-100">
                <div className="card-body p-0">
                  <p className="head">
                    <i className="bi bi-clock-history mx-2"></i> Transactions
                  </p>
                  <div className="container mt-4"></div>
                  <div className="container">
                    <div className="row">
                      <div className="alert alert-danger p-2" role="alert">
                        <span>
                          {" "}
                          <i className="bi bi-exclamation-triangle"></i> You
                          currrently have no transactions
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
