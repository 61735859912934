import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const EditorLogin = () => {
  const [email, setEmail] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setredirect] = useState("");
  const [role] = useState("editor");

  const showPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleEvent = (e) => {
    e.preventDefault();
    if (!email && password) {
      toast.error("Email or password cannot be empty");
    } else {
      setLoading(true);
      axios.defaults.withCredentials = true;
      const baseURL = process.env.REACT_APP_BASEURL;
      const response = axios
        .get(`${baseURL}/sanctum/csrf-cookie`)
        .then((response) => {
          const baseURL = process.env.REACT_APP_BASEURL;
          return axios.post(
            `${baseURL}/login`,
            {
              email,
              password,
              role,
            },
            {
              xsrfHeaderName: "X-XSRF-TOKEN",
              withCredentials: true,
            }
          );
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            localStorage.setItem("EditorId", res.data.data.uuid);
            setLoading(false)
            setredirect("/editor/assessment");
            localStorage.setItem(
              "secondLevelAccess",
              res.data.data.second_level_access
            );
            setTimeout(() => {
              window.location.reload(1);
            }, 3000);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
            setLoading(false);
          } else if(error.response.status === 403) {
            toast.warn(error.response.data.message)
            setLoading(false)
          }
        });
      return response;
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center">
        <ToastContainer autoClose={4000} />
        <div className="col-md-6">
          <div className="my-5 card w-100 border-0 shadow">
            <div className="card-body">
              <div className="form-head">
                <h3 className="text-center text-primary">
                  Login to your account
                </h3>
                <p className="text-center text-dark">
                  Welcome back!
                </p>
              </div>
              <form className="form" onSubmit={handleEvent}>
                <label htmlFor="name">Email</label>
                <input
                  type="email"
                  required
                  className="form-control mb-3"
                  placeholder="johndoe@yahoo.com"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="password">Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control mb-3"
                  placeholder="**********"
                  id="password"
                  required
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onClick={showPassword}
                />
                <span className="mx-3">Show Password</span>
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={loading}
                >
                  {!loading ? (
                    <h6>Login</h6>
                  ) : (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only"></span>
                    </div>
                  )}
                </button>
              </form>
              <p className="text-center text-dark">
                Don't have an Account? <Link to="/register">Register</Link>
              </p>
              <p className="text-center">
                <Link to="/forgot-password">Forgot Password?</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorLogin;
