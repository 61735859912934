import axios from "axios";
import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { Redirect } from "react-router-dom";
import "./Feeds.css";
import { toast, ToastContainer } from "react-toastify";
import { EditorId } from "../../includes/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const SingleFeed = (props) => {
  const [redirect, setRedirect] = useState("");
  const [job, setJob] = useState([]);
  const [loading, setLoading] = useState(true);
  const [placeOrder, setPlaceOrder] = useState(false);
  console.log(props.match.params.id);

  const pickOrder = (e) => {
    console.log("order picked");
    setPlaceOrder(true);
    axios.defaults.withCredentials = true;
    const baseURL = process.env.REACT_APP_BASEURL;
    const response = axios
      .get(`${baseURL}/sanctum/csrf-cookie`)
      .then((response) => {
        const baseURL = process.env.REACT_APP_BASEURL;
        return axios.post(
          `${baseURL}/api/v1/editors/${EditorId}/orders`,
          {
            order_id: props.match.params.id,
          },
          {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          }
        );
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          toast.success(res.data.message);
          setLoading(false);
          setTimeout(() => {
            setRedirect("/editor/order-history");
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setLoading(false);
        } else if (error.response.status === 403) {
          toast.warn(error.response.data.message);
          setLoading(false);
        }
      });
    return response;
  };

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/editors/${EditorId}/orders/${props.match.params.id}`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setJob(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        }
      });
  }, [props.match.params.id]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container-fluid my-5">
      <ToastContainer autoClose={3000} />
      <div className="row">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <div className="row">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: "200px" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div className="col-md-12">
                  <div className="col-md-8">
                    <div className="card border-0 shadow">
                      <div className="card-body p-5">
                        <div className="row my-3">
                          <div className="col-md-6 title">Title:</div>
                          <div className="col-md-6">{job.title}</div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title">Category:</div>
                          <div className="col-md-6">{job?.service?.name}</div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title title">
                            Number of Words:
                          </div>
                          <div className="col-md-6">{job.number_of_words}</div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title">Price:</div>
                          <div className="col-md-6">
                            {job.currency}{" "}
                            <CurrencyFormat
                              value={job.price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title">Urgency:</div>
                          <div className="col-md-6">{job?.urgency}</div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title">Field</div>
                          <div className="col-md-6">{job.field}</div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title">English Version:</div>
                          <div className="col-md-6">{job.english_version}</div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title">Bibliography:</div>
                          <div className="col-md-6">
                            {job.bibliography === 1 ? "Yes" : "No"}
                          </div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title">Footnotes:</div>
                          <div className="col-md-6">
                            {job.footnotes === 1 ? "Yes" : "No"}
                          </div>
                        </div>

                        <div className="row my-3">
                          <div className="col-md-6 title">Style Guide:</div>
                          <div className="col-md-6">{job.style_guide}</div>
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn btn-primary my-4 shadow"
                      type="submit"
                      onClick={pickOrder}
                      disabled={placeOrder}
                    >
                      Pick Order
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleFeed;
