import axios from "axios";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { Link, Redirect } from "react-router-dom";
import { userId } from "../UserProfile";
import "./CompletedOrders.css";
import author from "../../images/author.png";
import Sidebar from "../Sidebar/Sidebar";

const UserCompletedOrders = () => {
  const [redirect, setRedirect] = useState("");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/users/${userId}/orders/completed`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setOrders(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setRedirect("/user/login");
        }
      });
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container my-5">
      <div className="row my">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "200px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : orders.length !== 0 ? (
            <div className="col-md-12">
              <div className="card border-0 shadow">
                <div className="card-body p-0">
                  <p className="head">
                    <i className="bi bi-clock-history mx-2"></i> Completed
                    Orders
                  </p>

                  <div className="row">
                    {orders?.map((order) => {
                      const {
                        id,
                        service,
                        price,
                        number_of_words,
                        currency,
                        title,
                        urgency,
                        uuid,
                      } = order;
                      return (
                        <div className="col-md-4 mx-3 mb-5" key={id}>
                          <div className="card mt-5 p-3">
                            <div className="cardbody">
                              <div className="text-center">
                                <img src={author} alt="images" />
                                <h4 className=" text-dark">{title}</h4>
                                <Link to={"/single-order/" + uuid}>
                                  <span className="details">See Details</span>
                                </Link>
                              </div>

                              <div className="row mt-4 text-center">
                                <div className="col-md-4 more-details">
                                  {number_of_words}
                                </div>
                                <div className="col-md-4 more-details">
                                  {urgency}
                                </div>
                                <div className="col-md-4 more-details">
                                  {currency}{" "}
                                  <CurrencyFormat
                                    value={price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""}
                                  />
                                </div>
                              </div>
                              <p className="text-center time-details">
                                {service?.created_at}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="card w-100 border-0 shadow">
                <div className="card-body p-0">
                  <p className="head">
                    <i className="bi bi-clock-history mx-2"></i> Completed
                    Orders
                  </p>
                  <div className="container mt-4"></div>
                  <div className="container">
                    <div className="row">
                      <div className="alert alert-danger p-2" role="alert">
                        <span>
                          {" "}
                          <i className="bi bi-exclamation-triangle"></i> You
                          have no completed orders at the moment
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCompletedOrders;
