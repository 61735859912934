import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'
import logo from '../../images/write-right-logo.png'

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3 text-white my-5">
            <Link to="/">
              <img src={logo} className="img-fluid" alt="footer-logo" />
            </Link>
            <p className='text-white mt-5'>
              Every service you need for <br /> error-free and effective documents.
            </p>
          </div>
          <div className="col-md-3 text-primary my-5">
            <h5 className="mt-3">Services</h5>
            <div className="mt-4">
              <a href="/services#academic-editing">
                <p className="text-white">Academic Editing</p>
              </a>
              <a href="/services#book-editing">
                <p className="text-white">Book Editing</p>
              </a>
              <a href="/services#transcribing-services">
                <p className="text-white">Transcribing Services</p>
              </a>
              <a href="/services#accent-training">
                <p className="text-white">Accent Training</p>
              </a>
            </div>
          </div>
          <div className="col-md-3 text-primary my-5">
            <h5 className="mt-3">Services</h5>
            <div className="mt-4">
              <a href="/services#SOP">
                <p className="text-white">Statement of Purpose</p>
              </a>
              <a href="/services#LOR">
                <p className="text-white">Letter of Recommendation</p>
              </a>
              <a href="/services#cover-letter">
                <p className="text-white">CoverLetter</p>
              </a>
            </div>
          </div>
          <div className="col-md-3 text-primary my-5">
            <h5 className="mt-3">Legal</h5>
            <div className="mt-4">
              <Link to="#">
                <p className="text-white">Privacy</p>
              </Link>
              <Link to="#">
                <p className="text-white">Terms of use</p>
              </Link>
              <Link to="#">
                <p className="text-white">Data Policy</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="footer-copy">
            <p className="text-white">
              {" "}
              &copy; 2022 Write Right | All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
