import Footer from "../Footer/Footer";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <div className="contact-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="contact-header">Contact Us</h2>
            </div>
          </div>
        </div>
      </div>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-center my-5">Let's Start a Conversation</h3>
            </div>
          </div>
          <div className="row bg-white shadow p-5 rounded">
            <div className="col-md-6 my-5">
              <h4 className="text-dark">Ask how we can help you:</h4>
              <div className="info shadow bg-primary">
                <h3 className="text-white mb-5">Info</h3>
                <p className="text-white mb-5">
                  <i className="bi bi-envelope"></i> writeright022@gmail.com
                </p>
                <p className="text-white mb-5">
                  <i className="bi bi-telephone"></i> +2349167235187
                </p>
                <p className="text-white mb-5">
                  Account name- Write Right Consulting Services GTBank -
                  0693456538 (Domiciliary Account)
                </p>
                <p className="text-white mb-5">UBA- 1017356435</p>
              </div>
            </div>
            <div className="col-md-6 my-5">
              <p>
                Feel free to contact us anytime. We will get back to you as soon
                as we can!
              </p>
              <form className="p-4">
                <div className="p-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                  />
                </div>
                <div className="p-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div className="p-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="p-3">
                  <button className="btn btn-primary w-100 shadow">SEND</button>
                </div>
              </form>
              <div className="float-end px-5">
                <i className="bi bi-facebook mx-2"></i>
                <i className="bi bi-instagram mx-2"></i>
                <i className="bi bi-twitter mx-2"></i>
                <i className="bi bi-linkedin mx-2"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
