import React from 'react'
import { Link } from 'react-router-dom';
import './Sidebar.css'
import logo from '../../images/write-right-logo.png'

const Sidebar = () => {
  return (
    <div className='sidebar-side'>
      <Link to='/admin/dashboard'>
        <h3 className="logo mx-3">
          <img src={logo} alt='logo' />
        </h3>
      </Link>
      <ul>
        <Link to="/admin/users">
          <li className='text-dark'>
            <i className="bi bi-people mx-3"></i>Users
          </li>
        </Link>
        <Link to="/admin/editors">
          <li className='text-dark'>
            <i className="bi bi-people mx-3"></i>Editors
          </li>
        </Link>
        <Link to="/admin/orders">
          <li className='text-dark'>
            <i className="bi bi-list-ul mx-3"></i>Orders
          </li>
        </Link>
        <Link to="/admin/interviews">
          <li className='text-dark'>
            <i className="bi bi-person-check mx-3"></i>Interview
          </li>
        </Link>
        <Link to="/admin/transactions">
          <li className='text-dark'>
            <i className="bi bi-arrow-repeat mx-3"></i>Transactions
          </li>
        </Link>
        <Link to="/admin/payments">
          <li className='text-dark'>
            <i className="bi bi-credit-card mx-3"></i>Payments
          </li>
        </Link>
      </ul>
    </div>
  );
}

export default Sidebar