import axios from 'axios';
import React, { useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar';

const Dashboard = () => {

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASEURL;
    axios
      .get(`${baseURL}/api/v1/categories`, {
        xsrfHeaderName: "X-XSRF-TOKEN",
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <div className="card border-0 shadow">
            <div className="card-body p-0">
              <p className="head">
                <i className="bi bi-clock-history mx-2"></i> User Account
              </p>
              <p className="m-5">
                To track and collect your orders or view your order history, please select the appropriate icon on the page. Click on <span className='text-primary'>'New Order'</span> to start 
                your orders
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard
