import React, { useRef, useState } from 'react'
import {Link, Redirect} from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import logo from '../images/write-right-logo.png'

export const userId = localStorage.getItem("userId");
export const EditorId = localStorage.getItem("EditorId")

const Navbar = () => {
  const [redirect, setRedirect] = useState("");
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const onClick = () => setIsActive(!isActive);

  const logout = (e) => {
    e.preventDefault()
    axios.defaults.withCredentials = true;
    const baseURL = process.env.REACT_APP_BASEURL;
    const response = axios
      .get(`${baseURL}/sanctum/csrf-cookie`)
      .then((res) => {
        console.log(res);
        const baseURL = process.env.REACT_APP_BASEURL;
        return axios.post(`${baseURL}/logout`, {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        });
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          toast.success(res.data.message);
          localStorage.clear("userId");
          localStorage.clear("EditorId")
          setTimeout(() => {
            setRedirect("/");
            window.location.reload(1);
          }, 3000);
          
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      });
    return response; 
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="navbar-light bg-white fixed-top shadow">
      <div className="container">
        <div className="row">
          <ToastContainer autoClose={3000} />
          <div className="col-md-12">
            <nav
              className="navbar navbar-expand-lg"
              data-navbar-on-scroll="data-navbar-on-scroll"
            >
              <div className="container-fluid">
                <Link to="/" className="navbar-brand" href="/">
                  <img
                    src={logo}
                    className="img-fluid"
                    width=""
                    alt="Write-right-logo"
                  />
                </Link>
                <button
                  onClick={onClick}
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  {userId || EditorId ? (
                    <ul
                      ref={dropdownRef}
                      className="navbar-nav ms-auto mb-2 mb-lg-0"
                    >
                      <li className="nav-item">
                        <Link
                          to="/services"
                          className="nav-link active text-dark"
                          aria-current="page"
                        >
                          Services
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/pricing" className="nav-link text-dark">
                          Pricing
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link text-dark">
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/contact" className="nav-link text-dark">
                          Contact
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          to="/user-profile"
                          className="nav-link text-primary"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          My Account{" "}
                          <i
                            className="fas fa-user-circle"
                            style={{ marginLeft: "10px", fontSize: "20px" }}
                          ></i>
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <Link
                              to={userId ? "/user" : "/editor/feeds"}
                              className="dropdown-item"
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <button onClick={logout} className="dropdown-item">
                              Logout
                            </button>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/user-profile"
                          className="nav-link text-dark"
                        ></Link>
                      </li>
                    </ul>
                  ) : (
                    <ul
                      ref={dropdownRef}
                      className="navbar-nav ms-auto mb-2 mb-lg-0"
                    >
                      <li className="nav-item">
                        <Link
                          to="/services"
                          className="nav-link active text-dark"
                          aria-current="page"
                        >
                          Services
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/pricing" className="nav-link text-dark">
                          Pricing
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link text-dark">
                          About
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/contact" className="nav-link text-dark">
                          Contact
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/login" className="nav-link text-dark">
                          Login
                        </Link>
                      </li>
                      <li>
                        <Link to="/register" className="btn btn-primary shadow">
                          Sign Up
                        </Link>
                      </li>
                    </ul>
                  )}

                  {/* <ul
                    ref={dropdownRef}
                    className="navbar-nav ms-auto mb-2 mb-lg-0"
                  >
                    <li className="nav-item">
                      <Link
                        to="/services"
                        className="nav-link active text-dark"
                        aria-current="page"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/pricing" className="nav-link text-dark">
                        Pricing
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact" className="nav-link text-dark">
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/editor/login" className="nav-link text-dark">
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/register" className="btn btn-primary shadow">
                        Sign Up
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar
