import axios from 'axios';
import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const Sidebar = () => {
  const [inactive, setInactive] = useState(false);
  const [redirect, setRedirect] = useState("");

  const logout = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to logout?")) {
      axios.defaults.withCredentials = true;
      const baseURL = process.env.REACT_APP_BASEURL;
      const response = axios
        .get(`${baseURL}/sanctum/csrf-cookie`)
        .then((res) => {
          console.log(res);
          const baseURL = process.env.REACT_APP_BASEURL;
          return axios.post(`${baseURL}/logout`, {
            xsrfHeaderName: "X-XSRF-TOKEN",
            withCredentials: true,
          });
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            toast.success(res.data.message);
            localStorage.clear("EditorId");
            setTimeout(() => {
              setRedirect("/");
              window.location.reload(1);
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            toast.error(error.response.data.message);
          }
        });
      return response;
    }
    return false;
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="card border-0 shadow mb-5">
      <div className="card-body m-0 p-0">
        <ToastContainer />
        <div className="menu-items">
          <ul>
            <li
              onClick={() => setInactive(true)}
              className={inactive ? "inactive" : ""}
            >
              <Link to="/user" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-arrow-counterclockwise mx-2"></i> My
                    Account
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/completed-orders" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-check2-square mx-2"></i> Completed
                    Orders
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/in-progress" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-arrow-counterclockwise mx-2"></i> In
                    progress
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/order-history" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-clock-history mx-2"></i> Order History
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/user-profile" className="side-item">
                <div className="menu-item">
                  <span>
                    <i className="bi bi-arrow-counterclockwise m-2"></i> Account
                    Settings
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <div className="menu-item">
                <p className="menu-item text-danger" onClick={logout}>
                  <i className="bi bi-box-arrow-right"></i> Logout
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className=" my-5 d-flex justify-content-center align-items-center">
          <a href="/#service" className="btn btn-primary shadow">
            New Order
          </a>
        </div>
      </div>
    </div>
  );
}

export default Sidebar