import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import { EditorId } from "../includes/Navbar";

const baseURL = process.env.REACT_APP_BASEURL;

const AssessmentOne = (props) => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [redirect, setRedirect] = useState("");
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [buttonLoad, setbuttonLoad] = useState(false);

  const testId = props?.match?.params?.id

  const submitTest = (e) => {
    e.preventDefault();
    setDisableConfirm(true);
    setbuttonLoad(true);
    axios
      .post(
        `${baseURL}/api/v1/editors/${EditorId}/tests/${testId}/submit`,{
          answers: selectedAnswers
  },
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        setDisableConfirm(false)
        setbuttonLoad(false);
        toast.success(response.data.message);
        if (response.data.status === "success") {
          setTimeout(() => {
            setRedirect("/editor/assessment");
          }, 3000);
        }

      })
      .catch(error => {
        setDisableConfirm(false);
        setbuttonLoad(true);
        if (error.response.status === 401) {
          setRedirect("/editor/login");
        } else if (error.response.status === 413) {
          setRedirect("/editor/assessment");
        }
      })
  };

  useEffect(() => {
    axios
      .get(
        `${baseURL}/api/v1/editors/${EditorId}/tests/${testId}`,
        {
          xsrfHeaderName: "X-XSRF-TOKEN",
          withCredentials: true,
        }
      )
      .then((response) => {
        setQuestions(response.data.data.questions);
        setLoading(true);
      })
      .catch(error => {
        if(error.response.status === 401) {
          setRedirect('/editor/login')
        }
      })
  }, [testId]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="container">
      <div className="row">
        {loading ? (
          <div className="card border-0 my-5">
            <div className="card-body">
              <ToastContainer autoClose={3000} />
              <h4 className="my-3">First Assesement</h4>
              <form onSubmit={submitTest}>
                {questions.map((question) => {
                  const { id, content, answers } = question;
                  return (
                    <div key={id}>
                      <p className="text-dark mt-5">
                        <strong>
                          {id}. {content}
                        </strong>
                      </p>
                      <div className="form-check">
                        {answers.map((answer, index) => {
                          return (
                            <div key={answer.id}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name={question.id}
                                value={answer.uuid}
                                id={answer.id}
                                onChange={(e) => {
                                  var new_ans = selectedAnswers;
                                  new_ans.push(e.target.value);

                                  setSelectedAnswers(new_ans);
                                }}
                              />
                              <label
                                className="form-check-label mb-3"
                                htmlFor={answer.id}
                              >
                                {answer.content}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <button
                  type="submit"
                  className="btn btn-primary shadow"
                  disabled={disableConfirm}
                >
                  Submit
                  {buttonLoad && (
                    <div
                      className="spinner-border text-light mx-3"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "300px" }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentOne;
