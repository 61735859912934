import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className='container'>
      <div className="row d-flex ">
        <div className="my-5 card jsutify-content-center align-items-center border-0 shadow">
          <div className="card-body">
            <h2>Error Page</h2>
            <Link to='/' className='btn btn-primary'>Back to Home</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
